import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import rest from '@feathersjs/rest-client';
import superagent from 'superagent';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';
var baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3030';
const socket = io(baseURL);
socket.io.timeout(10000); // Establecer el timeout en 10 segundos (por ejemplo)
const app = feathers()
    .configure(socketio(socket))
    // .configure(rest(baseURL).superagent(superagent))
    .configure(auth({ storage: window.localStorage }));
export default app;


