import { NotificationsActiveOutlined, NotificationsOffOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Zoom } from '@mui/material';
import React, { useEffect } from 'react';

const NotificationComponent = () => {
    const [showMessage, setShowMessage] = React.useState(false);


    return (
        [<IconButton onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowMessage(!showMessage);
        }} size='small'>
            {
                window?.Notification?.permission === 'granted' ?
                    <NotificationsActiveOutlined />
                    :
                    <NotificationsOffOutlined />
            }
        </IconButton>,
        <Dialog
            open={showMessage}
            TransitionComponent={Zoom}
            onClose={() => setShowMessage(false)}
        >
            <DialogTitle>
                {'Administra tus notificaciones'}
            </DialogTitle>
            <DialogContent>
                {'¡Ve a la configuración de tu dispositivo!'}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowMessage(false)}>
                    {'Aceptar'}
                </Button>
            </DialogActions>
        </Dialog>
        ]
    );
};

export default NotificationComponent;
