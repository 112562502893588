import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';

import { useSelector } from 'react-redux';

// const color = blueGrey
const createThemeCuscom = ({ mode = 'dark', type = 'dark', color = blueGrey }) => {
    let theme = createTheme({
        overrides: {
            MuiPaper: {
                root: {
                    borderRadius: '10px',
                },
                rounded: {
                    borderRadius: '10px',
                }
            },
            MuiCardHeader: {
                root: {
                    position: 'relative',
                }
            },

            MuiFormControl: {
                marginNormal: {
                    marginTop: '4px',
                    marginBottom: '8px'
                },
                root: {
                    marginTop: '4px',
                    marginBottom: '8px'
                }
            },
            MuiOutlinedInput: {
                input: {
                    padding: '8px 14px'
                }
            },
            MuiInputLabel: {
                outlined: {
                    transform: 'translate(14px, 10px) scale(1)'
                }
            },
            MuiDrawer: {
                paper: {
                    // backgroundColor: grey
                }
            },
            MuiFormHelperText: {
                root: {
                    position: 'absolute',
                    bottom: '-4px',
                    left: '-14px',
                    color: '#f3564b',
                    width: '100%'
                },
                contained: {
                    margin: '0px 14px 0'
                }
            },
            MuiInputBase: {
                root: {
                    marginBottom: '10px'
                }
            },

        },
        palette: {
            semitransparent: `rgba(${color[500]},0.5)`,
            primary: color,
            secondary: {
                main: '#fafafa'
            },
            type: 'light',
            mode,
        },
        typography: {
            fontFamily: 'Nunito',
        },

        MuiExpansionPanelSummary: {

            root: {
                "&$expanded": {
                    boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)"
                }
            }
        }
    });
    return theme;
}
export default function Layout(props) {
    const themeCustom = useSelector((state) => state.session.theme);
    console.log('themecustom', themeCustom);
    let customTheme = createThemeCuscom(themeCustom || {})
    return (
        <ThemeProvider theme={customTheme}>
            {props.children}
        </ThemeProvider>
    );
}

