import React, { Suspense, useEffect, useState } from "react"
import { Canvas } from "react-three-fiber"
import { ContactShadows, Environment } from "@react-three/drei"
import Model3D from "../Model3D"
import CameraController from "../CameraController"
import { Badge, Box, Button, ButtonGroup, Typography } from "@mui/material"
import { Rotate90DegreesCcwOutlined, RotateLeftOutlined } from "@material-ui/icons"
import { ThreeSixtyOutlined } from "@mui/icons-material"
import ViewerLevel from "../ViewersLevels/ViewerLevel"
import { useSpeechSynthesis } from 'react-speech-kit';

export default function Scene3D({ selected }) {
    const [selector, setSelector] = useState('x');
    const { speak, cancel } = useSpeechSynthesis();

    useEffect(() => {
        console.log('error', selected);
        if (selected?.error) {
            cancel();
            const voices = speechSynthesis.getVoices().filter(e => e.lang === 'es-MX');
            const text = selected?.error !== true ? selected?.error : `Total Wan ha detectado vibraciones fuera de los límites establecidos en el Motor de la ${selected?.vc_name}, por lo que se sugiere revisión física del componente.`
            speak({ text: text, voice: voices[0] || undefined })
            console.log('error', selected,voices[0] );

        }
        return () => {
            cancel();
        }
    }, [selected?.error])
    return (
        <Box style={{ width: '100%', height: '100%' }}>
            <Canvas concurrent pixelRatio={[1, 1.5]} camera={{ position: [0, 0, 3] }}>
                <CameraController />
                <ambientLight intensity={0.5} />
                <ambientLight intensity={1} color='red' position={[0, 0, 0]} />
                <spotLight intensity={0.3} angle={0.3} penumbra={1} position={[5, 25, 20]} shadow-bias={-0.0001} />
                <Suspense fallback={null}>
                    <Model3D selector={selector} active={true}/>
                    <Environment files="assets/models/demo/royal_esplanade_1k.hdr" />
                    <ContactShadows
                        rotation-x={Math.PI / 2}
                        position={[0, -0.8, 0]}
                        opacity={0.5}
                        width={10}
                        height={10}
                        blur={2}
                        far={2}
                    />
                </Suspense>
            </Canvas>
            {/* <Box position={'absolute'} bottom={0} width='100%' height={'100%'} style={{backgroundColor: 'red', opacity: 0.5}}/> */}

            <Box position={'absolute'} top={0} width='100%'>
                <Box p={1} position='relative'>
                    <Box style={{ backgroundImage: 'linear-gradient(to right, blue,green,yellow,red)', opacity: 0.5, width: '100%', height: 35, borderRadius: 8, boxShadow: '0px 0px 10px gray' }}>

                    </Box>
                    <Box style={{ borderRadius: 2, width: 4, height: '100%', position: 'absolute', top: 0, left: '45%', backgroundColor: 'gray', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>

                    </Box>
                    <Box style={{ borderRadius: 2, width: 4, height: '100%', position: 'absolute', top: 38, left: '45%', flexDirection: 'column', display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' }}>
                        <Typography fontWeight={700} textAlign={'center'} color='gray' style={{ fontSize: 'auto', float: 'center', marginLeft: '-180%' }}>
                            {'78 ºC'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {
                <Box p={2} position={'absolute'} bottom={50} width='100%' left={0}>
                    <Box p={1} position='relative'>
                        <Typography fontWeight={700} textAlign={'center'} color='gray' style={{ fontSize: 'auto', float: 'center' }}>
                            {'Se ha superado el límite de vibración establecido'}
                        </Typography>
                    </Box>
                </Box>
            }
            <Box position={'absolute'} bottom={0} width='100%'>
                <Box p={1} position={'relative'}>
                    <ButtonGroup color="primary" variant="contained" style={{ width: '100%' }}>
                        <Button variant={selector === null ? 'contained' : 'outlined'} fullWidth onClick={() => setSelector(null)}>
                            <ThreeSixtyOutlined />
                        </Button >
                        <Button variant={selector === 'x' ? 'contained' : 'outlined'} fullWidth onClick={() => setSelector('x')}>
                            {'Falla detectada'}
                        </Button >
                        {/* <Button variant={selector === 'y' ? 'contained' : 'outlined'} fullWidth onClick={() => setSelector('y')}>
                            {'Eje: Y'}
                        </Button>
                        <Button variant={selector === 'z' ? 'contained' : 'outlined'} fullWidth onClick={() => setSelector('z')}>
                            {'Eje: Z'}
                        </Button> */}
                    </ButtonGroup>
                </Box>
            </Box>
        </Box>

    )
}
