import React, { useEffect, useState } from 'react';
import {
    useSelector,
} from 'react-redux';
import { Helmet } from 'react-helmet';
import '../../App.css';
import { Avatar, Box, Button, Checkbox, Dialog, DialogActions, Grid, IconButton, ListItem, Paper, Select, Slide, TextField, Typography } from '@mui/material';
import { AddBoxOutlined, HealthAndSafetyOutlined, MoreHorizOutlined, PlaylistAdd, QrCode2Outlined, ShareOutlined, WarningAmberOutlined } from '@mui/icons-material';
import firebase from '../../services/firebase';
import IconMask from '../../components/IconMask';
import { DialogContent, DialogTitle } from '@material-ui/core';
import QRIconComponent from '../../components/QRIconComponent';
import ModalItem from '../../components/ModalItem';
const db = firebase.database().ref();

function SubComponents({ parent, showDetails }) {
    const [selected, setSelected] = useState(null)
    const [items, setItems] = useState([])
    useEffect(() => {
        db.child('clients').child('items').orderByChild('id_parent').equalTo(parent.id || 'dddd').on('value', (snapshot) => {
            const results = snapshot.val();
            if (results) {
                const data = Object.values(results).map((item, index) => {
                    return {
                        ...item,
                        id: Object.keys(results)[index]
                    }
                })
                setItems(data);
            }
        })
        return () => {
            db.child('clients').child('items').off();
        }
    }, [])

    return (
        <Grid direction={'column'} container display={'flex'} style={{ height: '100%' }}>
            <Helmet >
                <title>React JS | Home</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
            <Grid p={1} item xs style={{ width: '100%', overflowY: 'scroll' }}>
                <Grid container style={{ width: '100%' }}>
                    {
                        items.length === 0 && (
                            <Box p={3} style={{ width: '100%' }}>
                                <Typography style={{ width: '100%', textAlign: 'center', padding: 16 }}>
                                    {'Sin áreas ni equipo que mostrar'}
                                </Typography>
                                <Box style={{ width: '100%', justifyItems: 'center' }}>
                                    <Button onClick={() => setSelected({})} fullWidth variant='contained' style={{ alignSelf: 'center', display: 'flex' }}>
                                        {'Agregar'}
                                    </Button>
                                </Box>
                            </Box>
                        )
                    }
                    {
                        (items).map((p, i) => {
                            let icon = 'area.svg';
                            switch (p.vc_type.toLowerCase()) {
                                case 'motor': {
                                    icon = 'motor.svg'
                                    break;
                                }
                                case 'tierras': {
                                    icon = 'grounding.svg'
                                    break;
                                }
                                case 'panel': {
                                    icon = 'control-panel.svg'
                                    break;
                                }
                                case 'luces': {
                                    icon = 'idea.svg'
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                            return (
                                <Box key={i} p={1} style={{ borderBottom: '0.5px solid rgba(200,200,200,0.3)', width: '100%' }} onClick={() => showDetails(p)}>
                                    <Grid item xs={12} flexDirection={'row'} container >
                                        <Grid item xs={12} container>
                                            <Grid item xs='auto' padding='8px 8px'>
                                                <IconMask url={`assets/icons/${icon}`} color={'gray'} size={30} />
                                            </Grid>
                                            <Grid item xs justifyItems={'center'} p={'3px 8px'}>
                                                <Typography variant='subtitle1' color='inherit' fontWeight={500}>
                                                    {p.vc_name}
                                                </Typography>
                                                <Typography variant='caption' color='primary' fontSize={10} height={10}>
                                                    {p.vc_code || `A${i}`}
                                                </Typography>
                                            </Grid>
                                            {
                                                !!p.error &&
                                                <Grid item xs='auto' container style={{ display: 'flex', flexDirection: 'column', paddingTop: 16 }}>
                                                    <WarningAmberOutlined htmlColor='orange' />
                                                </Grid>
                                            }
                                            <Grid item xs='auto' container >
                                                <Checkbox variant='' defaultChecked={false} onClick={(e) => e.stopPropagation()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <ModalItem
                selected={selected}
                setSelected={setSelected}
            />
        </Grid>
    );
}

export default SubComponents;