import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconMask from '../IconMask';
import SearchSelect from '../forms/SearchSelect';
import { Autocomplete, Box, colors, Divider, Grid, IconButton, inputLabelClasses, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { blueGrey } from '@mui/material/colors';
import { setTheme } from '../../redux/session';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const colorsList = [
    // { id:'common'},
    { label: 'Rojo', id: 'red', value: colors.red, colorIcon: colors.red[500] },
    { label: 'Rosa', id: 'pink', value: colors.pink, colorIcon: colors.pink[500] },
    { label: 'Purpura', id: 'purple', value: colors.purple, colorIcon: colors.purple[500] },
    { label: 'Purpura oscuro', id: 'deepPurple', value: colors.deepPurple, colorIcon: colors.deepPurple[500] },
    { label: 'Indigo', id: 'indigo', value: colors.indigo, colorIcon: colors.indigo[500] },
    { label: 'Azul', id: 'blue', value: colors.blue, colorIcon: colors.blue[500] },
    { label: 'Azul claro', id: 'lightBlue', value: colors.lightBlue, colorIcon: colors.lightBlue[500] },
    { label: 'Cyan', id: 'cyan', value: colors.cyan, colorIcon: colors.cyan[500] },
    { label: 'Olivo', id: 'teal', value: colors.teal, colorIcon: colors.teal[500] },
    { label: 'Verde', id: 'green', value: colors.green, colorIcon: colors.green[500] },
    { label: 'Verde claro', id: 'lightGreen', value: colors.lightGreen, colorIcon: colors.lightGreen[500] },
    { label: 'Lima', id: 'lime', value: colors.lime, colorIcon: colors.lime[500] },
    { label: 'Amarillo', id: 'yellow', value: colors.yellow, colorIcon: colors.yellow[500] },
    { label: 'Ambar', id: 'amber', value: colors.amber, colorIcon: colors.amber[500] },
    { label: 'Naranja', id: 'orange', value: colors.orange, colorIcon: colors.orange[500] },
    { label: 'Naranja oscuro', id: 'deepOrange', value: colors.deepOrange, colorIcon: colors.deepOrange[500] },
    { label: 'Cafe', id: 'brown', value: colors.brown, colorIcon: colors.brown[500] },
    { label: 'Gris', id: 'grey', value: colors.grey, colorIcon: colors.grey[500] },
    { label: 'Azul-gris', id: 'blueGrey', value: colors.blueGrey, colorIcon: colors.blueGrey[500] },
];
const modesList = [
    { label: 'Claro', value: 'light' },
    { label: 'Oscuro', value: 'dark' },
]
export default function ThemeButton({ mode = 'button', backgroundColor = 'transparent', drawerOpen = true }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();

    const themeCustom = useSelector((state) => state.session.theme);
    // console.log('the,e', themeCustom);
    const [item, setItem] = React.useState({ mode: modesList.find(e => e.value === theme.palette.mode), color: colorsList.find(e => e.value === (themeCustom?.color || [])[50] || e.value === (theme.palette.primary || [])[50]) });
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const handleClickOpen = () => {
        setOpen(true);
    };
    // console.log('colors', colors, Object.keys(colorsList));
    const handleClose = () => {
        setOpen(false);
    };
    // React.useEffect(() => {
    //     setItem({ ...item, id_institucion: null })
    // }, [institution])

    const fields = [
        {
            id: 'mode',
            icon: 'assets/icons/theme.svg',
            numeric: false,
            readOnly: true,
            disablePadding: true,
            value: null,
            label: 'Modo de fondo',
            component: "select",
            relation: {
                id_type: 'toOne',
                options: modesList,
                vc_nameTable: "institutions",
                vc_nameReducer: "institutions",
                key: "id_institucion",
                keyTable: "id",
                fieldsDisplay: [
                    "label"
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'color',
            icon: 'assets/icons/paint.svg',
            numeric: false,
            readOnly: true,
            disablePadding: true,
            value: null,
            label: 'Color principal',
            component: "select",
            relation: {
                id_type: 'toOne',
                options: colorsList,
                vc_nameTable: "institutions",
                vc_nameReducer: "institutions",
                key: "id_institucion",
                keyTable: "id",
                fieldsDisplay: [
                    "label"
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
    const getLabel = ((field, item) => {
        let label = '';
        field?.relation?.fieldsDisplay.map((key, index) => {
            label = `${label}${item[key] || ''}${field?.relation?.fieldsDisplaySeparators[index] || ''}`
        });
        return label;
    })
    return (
        [
            // <Button variant="outlined"
            //     onClick={handleClickOpen}
            //     endIcon={
            //         <IconMask color={'black'} url={'assets/icons/iteration.svg'} size={25} />
            //     }
            // >
            //     {'Agregar dispositivo'}
            // </Button>,
            mode === 'custom' ?
                <ListItem key={'theme'} disablePadding sx={{ display: 'block', backgroundColor: open ? 'rgba(200,200,200,0.1)' : 'transparent' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2,
                        }}
                        onClick={handleClickOpen}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: drawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <IconMask color={open ? theme.palette.primary.main : theme.palette.mode === 'dark' ? 'gray' : 'black'} url={'assets/icons/paint.svg'} size={33} />
                        </ListItemIcon>
                        <ListItemText primary={'Diseño'} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem> :
                <IconButton key={'iconButton'} size='large' variant="outlined"
                    onClick={handleClickOpen}
                    style={{ backgroundColor: backgroundColor || theme.palette.primary.main }}
                >
                    <IconMask color={theme.palette.mode === 'dark' ? 'white' : 'black'} url={'assets/icons/paint.svg'} size={25} />
                </IconButton>
            ,
            <Dialog
                key={'dialog'}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullScreen={!isMd}
                PaperComponent={Paper}
            >
                <DialogTitle>{"Personaliza tu expediencia"}</DialogTitle>
                <DialogContent>
                    {
                        fields.map((field, index) => {
                            // console.log('value', item[field.id])
                            return (
                                <Grid key={index} direction='row' width={500} maxWidth={'100%'} container marginBottom={1}>
                                    <Grid key={field.id} item xs>
                                        <Autocomplete
                                            value={item[field.id] || null}
                                            renderInput={(params) => <TextField {...params}
                                                label={field.label}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment:
                                                        <Box marginRight={1}><IconMask color='grey' url={field.icon} size={25} /></Box>
                                                    , disableUnderline: true, placeholder: field.label, style: { padding: '6px 10px', backgroundColor: 'rgba(200,200,200,0.3)', borderRadius: 10, fontWeight: 700 }
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        // set the color of the label when not shrinked
                                                        color: "gray",
                                                        padding: '5px 10px',
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                            // set the color of the label when shrinked (usually when the TextField is focused)
                                                            padding: '0px 14px',
                                                        }
                                                    }
                                                }}
                                                variant={'standard'} />}
                                            onChange={(e, n) => {
                                                console.log('new state', { ...item, [field.id]: n })
                                                setItem({ ...item, [field.id]: n })
                                            }}
                                            options={field.relation.options}
                                            renderOption={(props, option) => {
                                                console.log('render option', option);
                                                return (
                                                    <ListItem
                                                        {...props}
                                                    >
                                                        {
                                                            option.icon && <IconMask color={item[field.id] === option ? theme.palette.primary.main : theme.palette.mode === 'dark' ? 'gray' : 'black'} url={option.icon} size={25} />
                                                        }
                                                        {
                                                            option.colorIcon && <Box p={1} width={30} height={30}>
                                                                <div style={{ backgroundColor: option.colorIcon, borderRadius: '50%', boxShadow: '0px 0px 5px rgba(120,120,120,0.3)', height: '100%', width: '100%' }} />
                                                            </Box>
                                                        }
                                                        <Typography>
                                                            {getLabel(field, option)}
                                                        </Typography>
                                                    </ListItem>
                                                )
                                            }}
                                            getOptionLabel={(item) => {
                                                return getLabel(field, item);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }

                </DialogContent>
                <DialogActions>
                    <Button key={'cancel'} onClick={handleClose}>Cancelar</Button>
                    <Button key={'accept'} onClick={() => {
                        console.log('click');
                        try {
                            dispatch(setTheme({ mode: item.mode?.value, color: item.color?.value }));
                        } catch (error) {
                            console.log(error);
                        }
                        handleClose()
                    }}>Guardar</Button>
                </DialogActions>
            </Dialog>
        ]
    );
}