import React, { useEffect, useState } from 'react';
import './styles.css'
import { Box, Button, Checkbox, Chip, Divider, Grid, ListItem, ListItemIcon, Menu, MenuItem, Paper, Typography } from '@mui/material';
import app from '../../services';
import moment from 'moment';
import { ArrowDropDown } from '@mui/icons-material';

const Selector = ({ value = { vc_nombre: 'Seleccione' }, colors = [], keyField = 'vc_nombre', measuresSelected = [], options = [], onSelect = () => { }, onSelectGroups = () => { }, onDelete = () => { } }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [clasesStruct, setClasesStruct] = useState([]);

    useEffect(() => {
        let active = true;
        const getOrderMeasures = async () => {
            if (active) {
                const res = await app.service('classes').find({
                    query: {
                        $limit: 100,
                        $skip: 0,
                        $and: [{ id_tipo: '2d2e3808-e401-4cd2-90fb-bebf304882d8' }],
                        $sort: {
                            createdAt: 'ASC'
                        }
                    }
                });
                const measObject = {};

                options.map(e => {
                    const clas = (res.data || []).find(c => c.id === e.id_clase);
                    if (clas) {
                        measObject[clas.vc_nombre] = clas;

                        let father = (res.data || []).find(c => c.id === clas.id_clase);
                        while (!!father) {
                            measObject[father?.vc_nombre] = father;
                            father = (res.data || []).find(c => c.id === father?.id_clase);
                        }
                    }
                })
                const measuresFilter = Object.values(measObject);
                const clases = measuresFilter.sort((a, b) => moment(a.createdAt) - moment(b.createdAt));
                // console.log('classes', clases);
                const getChildren = (clase) => {
                    const measures = options.filter(e => e.id_clase === clase.id);
                    measures.sort((a, b) => (a.vc_nombre > b.vc_nombre ? 1 : -1))
                    return ({
                        ...clase,
                        measures,
                        childrens: clases.filter(e => e.id_clase === clase.id).map((c) => getChildren(c))
                    })
                }
                const clasesOrder = clases.filter(e => e.id_clase === null).map(e => getChildren(e));
                setClasesStruct(clasesOrder);
            }
        }
        getOrderMeasures()
        return (() => {
            active = false;
        })
    }, [options])
    const renderItem = ((item, index) => {
        return (
            <MenuItem
                key={`${item.id}-${index}`}
                onClick={() => {
                    setAnchorEl(null);
                    onSelect(item);
                }}
            >
                <Grid container>
                    <Grid key={'name'} item xs style={{ paddingTop: 8 }}>
                        {item[keyField]}
                    </Grid>
                    <Grid key={'measure'} item xs minWidth={10} />
                    <Grid item xs='auto'>
                        <Box p={1} flexDirection={'row'} justifyContent='center' display='flex' style={{ height: '100%', fontSize: '0.8rem', fontWeight: 700 }}>
                            <Typography color='primary'>
                                {item.medida?.vc_abreviatura}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid key={'action'} item xs='auto'>
                        {/* <ListItemIcon style={{ justifyContent: 'end' }}> */}
                        <Checkbox size='small' color='primary' onChange={() => onSelect(item)} checked={measuresSelected.includes(item)} style={{ alignSelf: 'end', marginRight: -12 }} />
                        {/* </ListItemIcon> */}
                    </Grid>
                </Grid>
            </MenuItem>
        )
    });
    const renderSections = ((item, index) => {
        const value = (item.measures || []).filter(e => measuresSelected.includes(e)).length === (item.measures || []).length && (item.measures || []).length > 0;
        return (
            [
                <Box
                    key={`${item.id}-${index}`}
                >
                    <ListItem
                        key={item[keyField]}
                        style={{ backgroundColor: item.id_clase === null ? 'transparent' : 'rgba(0,0,0,0.1)', borderRadius: 10, display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Typography color={'primary'} fontWeight={700}>
                            {item[keyField]}
                        </Typography>
                        {item.childrens?.length === 0 && <ListItemIcon style={{ justifyContent: 'end' }}>
                            <Checkbox size='small' color='primary' onChange={() => {
                                onSelectGroups(item);
                                setAnchorEl(null);
                            }} checked={value} style={{ alignSelf: 'end', marginRight: -12 }} />
                        </ListItemIcon>}
                    </ListItem>
                    <Paper style={item.id_clase === null ? { boxShadow: '0px 0px 1px darkGray', borderRadius: 10, margin: 10, overflow: 'hidden' } : {}}>
                        {
                            item.measures?.map((item, index) => {
                                return renderItem(item, index)
                            })
                        }
                        {
                            item.childrens?.map((item, index) => {
                                return renderSections(item, index)
                            })
                        }
                    </Paper>

                </Box>
                // ,
                // item.measures.map((measure, index) => {
                //     return renderItem(measure, index);
                // }),
                // item.childrens.map((clase, index) => {
                //     return renderItem(clase, index);
                // })
            ]
        )
    })
    return (
        [
            <Box
                key={'selector1'}
                width={'100%'}>
                <Button
                    fullWidth
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    color={'inherit'}
                    style={{ fontWeight: 700, padding: 8 }}

                >
                    {measuresSelected.length > 0 ? `Mediciones` : 'Seleccione'}
                    <Box height={'100%'} marginRight={1} justifyContent='center' display={'flex'} flexDirection='column' position='absolute' top={1} right={1}><ArrowDropDown /></Box>
                </Button>
                <Divider display={measuresSelected.length > 0 ? 'flex' : 'none'} />
                <Box p={measuresSelected.length > 0 ? '4px' : 0} justifyContent={'space-around'}>
                    {
                        measuresSelected.length > 0 &&
                        measuresSelected.map((item, index) => {
                            return (
                                <Chip
                                    key={index}
                                    label={item.vc_tag || item.vc_fieldName}
                                    onClick={() => onSelect(item)}
                                    // onDelete={() => onDelete(item)}
                                    style={{ margin: 4, border: `2px solid ${colors[index]}` }}
                                />
                            )
                        })
                    }
                </Box>

            </Box>
            ,
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
                style={{ width: anchorEl?.width || '100%' }}
            >
                {
                    !!anchorEl && !!clasesStruct.length ?
                        clasesStruct.map((option, index) => {
                            return renderSections(option, index);
                        }) :
                        <MenuItem
                            key={'menu end'}
                            style={{ width: '100%' }}
                            onClick={() => setAnchorEl(null)}
                        >
                            {'Sin datos para mostrar'}
                        </MenuItem>
                }
            </Menu>]
    )

}

export default Selector;
