import moment from "moment";

const repository = {
  generateTimeIntervals: function (duration, date, existingIntervals = []) {
    let result = [];
    const intervals = [];
    console.log('intervals', duration, date);
    const intervalsNumber = 1440 / duration;
    const start = moment(date || null).startOf("day"); // fecha actual

    for (let index = 0; index < intervalsNumber; index++) {
      intervals.push({
        start: moment(start).add(duration * index, "minutes"),
        end: moment(start)
          .add(duration * index, "minutes")
          .add(duration, "minutes"),
        duration: duration,
      });
    }
    let removesItems = [];
    let newItems = [];
    existingIntervals.forEach((existent, index) => {
      const first = intervals.filter(
        (e) =>
          e.start * 1 < moment(existent.start) * 1 &&
          e.end * 1 > moment(existent.start) * 1
      );
      const removes = intervals.filter(
        (e) =>
          e.start * 1 > moment(existent.start) * 1 &&
          e.end * 1 < moment(existent.end) * 1
      );
      const last = intervals.filter(
        (e) =>
          e.start * 1 < moment(existent.end) * 1 &&
          e.end * 1 > moment(existent.end) * 1
      );
      if (first.length > 0) {
        console.log("first", first);
        newItems.push({
          ...first[0],
          end: moment(existent.start),
          duration: first[0].start.diff(moment(existent.start), "minutes"),
        });
        removesItems.push(first);
      }
      if (last.length > 0) {
        newItems.push({
          ...last[0],
          start: moment(existent.end),
          duration: moment(existent.end).diff(last[0].end, "minutes"),
        });
        removesItems.push(last);
      }
      newItems.push({
        ...existent,
        start: moment(existent.start),
        end: moment(existent.end),
        exist: true,
      });
      removesItems = removesItems.concat(removes);
    });
    result = intervals.filter((e) => !removesItems.includes(e));
    result = result.concat(newItems);
    result.sort((a, b) => {
      if (a.start.isBefore(b.start)) {
        return -1;
      } else if (a.start.isAfter(b.start)) {
        return 1;
      } else {
        return 0;
      }
    });

    return result.filter(e => e.start * 1 >= date * 1).map((e) => {
      // console.log(e.start.format("YYYY-MM-DD HH:mm:ss"), e.duration);
      return {
        ...e,
        start: e.start.format("YYYY-MM-DD HH:mm:ss"),
        end: e.end.format("YYYY-MM-DD HH:mm:ss"),
      };
    });
  },
};

export default repository;
