import { blueGrey } from '@mui/material/colors';
import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    globalUser: null,
    token: null,
    idPaciente: 0,
    institution: null,
    theme: {
      mode: 'dark',
      color: blueGrey,
    }
  },
  reducers: {
    setGlobalUser: (state, action) => {
      state.globalUser = action.payload;
    },
    setTheme: (state, action) => {
      console.log('action action', action);
      state.theme = action.payload;
    },
    setLocation: (state, action) => {
      state.globalUser = action.payload;
    },
    setInstitution: (state, action) => {
      state.institution = action.payload;
    },
    removeGlobalUser: (state, action) => {
      state.globalUser = null;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    removeToken: (state) => {
      state.token = null;
    },
    removeInstitution: (state) => {
      console.log('instituyion delete');
      state.institution = null;
    },
    setIdPaciente: (state, action) => {
      state.idPaciente = action.payload;
    },
    removeIdPaciente: (state) => {
      state.idPaciente = 0;
    }
  },
})

export const { setGlobalUser, setLocation, setInstitution, removeGlobalUser, setToken, removeToken, setIdPaciente, removeIdPaciente, removeInstitution, setTheme } = sessionSlice.actions;

export default sessionSlice.reducer;