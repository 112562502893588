import React, { useState } from 'react';

const CURPInput = () => {
  const [inputValue, setInputValue] = useState('');
  const [isScannerInput, setIsScannerInput] = useState(false);

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;

    // Verificar si la entrada es más rápida de lo normal
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - (inputValue ? inputValue.timestamp : 0);

    if (timeDifference < 100) {
      setIsScannerInput(true);
    } else {
      setIsScannerInput(false);
    }

    // Verificar si la longitud es uniforme
    if (inputValue.length !== newInputValue.length) {
      setIsScannerInput(false);
    }

    setInputValue({
      value: newInputValue,
      timestamp: currentTime,
    });
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue.value}
        onChange={handleInputChange}
        style={{ borderColor: isScannerInput ? 'red' : 'initial' }}
      />
      {isScannerInput && <p>Entrada de escáner detectada.</p>}
    </div>
  );
};

export default CURPInput;
