import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField, inputLabelClasses } from "@mui/material";
import React, { useState } from "react";

export default function PasswordInput({ field, ...props }) {
    const [showPassword, setShowPassword] = useState(false)
    console.log('entro en passss')
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <FormControl fullWidth variant="standard">
            <InputLabel {...{

                sx: {
                    // set the color of the label when not shrinked
                    color: "gray",
                    padding: '5px 10px',
                    [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        padding: '0px 14px',
                    }
                }
            }} >
                {props.label}
            </InputLabel>
            <Input
                // {...props}
                key={props.key}
                id={props.id}
                {...{ disableUnderline: true, placeholder: field.label, type: showPassword ? 'text' : 'password', style: { padding: '6px 10px', backgroundColor: 'rgba(200,200,200,0.1)', borderRadius: 10 } }}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
    /*
        return (<TextField
            InputLabelProps={{
                sx: {
                    // set the color of the label when not shrinked
                    color: "gray",
                    padding: '5px 10px',
                    [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        padding: '0px 14px',
                    }
                }
            }}
            InputProps={{ disableUnderline: true, placeholder: field.label, style: { padding: '8px 10px', backgroundColor: 'rgba(200,200,200,0.3)', borderRadius: 10 } }}
    
            inputProps={{ inputMode: field.numeric ? 'numeric' : 'text', pattern: '[0-9]*' }}
            {...props}
            // type={showPassword ? 'text' : 'password'}
            type="password"
            inputProps={{ placeholder: 'hola', type: 'password' }}
        />)
        */
}