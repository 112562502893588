import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Helmet } from 'react-helmet';
import '../../App.css';
import { Avatar, Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase, ListItem, Paper, Select, Slide, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { AddBoxOutlined, ArrowBackIosNewOutlined, FormatListBulletedOutlined, HealthAndSafetyOutlined, MoreHorizOutlined, PlaceOutlined, PlaylistAdd, QrCode2Outlined, ShareOutlined, WarningAmberOutlined } from '@mui/icons-material';
import firebase from '../../services/firebase';
import IconMask from '../../components/IconMask';
import ModalItem from '../../components/ModalItem';
import app from '../../services';
import { common } from '../../repositories';
import { secciones, dispositivosSecciones, favorites } from '../../constants/models';
import Maps from '../../components/Maps';
import { useTheme } from '@mui/styles';
import VoiceRecognition from '../../components/VoiceRecognition';
import CatalogsIconButton from '../../components/customForms/CatalogsIconButton';
import ItemDashboardFavorites from '../../components/ItemDashboardFavorites';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { TransitionUp } from '../../common/TransitionComponents';
import ItemDashboard from '../../components/ItemDashboard';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function DashboardView() {
  const token = useSelector(state => state.session.token);
  const [searchText, setSearchText] = useState('');
  const user = useSelector(state => state.session.globalUser);
  const institution = useSelector(state => state.session.institution);
  const [selected, setSelected] = useState(null)
  const [mode, setMode] = useState('list');
  const history = useHistory();
  const [sectionsDevices, setSectionsDevices] = useState([])
  const [sectionsDevicesAlarms, setSectionsDevicesAlarms] = useState([])
  const [sectionsDevicesSelected, setSectionsDevicesSelected] = useState(null)
  const [items, setItems] = useState([])
  const favoritesServices = app.service('favorites');
  const alarmsServices = app.service('devices-alarms');
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  async function getData() {
    const response = await favoritesServices.find({
      query: {
        $and: [{
          id_institucion: institution?.id,
        }, {
          id_usuario: user?.id,
        }]
      }

    })
    console.log('response.datcccca', response.data);
    setSectionsDevices(response.data);
  }
  async function getAlarmsData() {
    const response = await alarmsServices.find({
      query: {
        $or: sectionsDevices.map(e => {
          return {
            id_dispositivoSeccion: e?.id_sectionDevice,
          }
        })
      }

    })
    console.log('alarmas', response.data);
    setSectionsDevicesAlarms(response.data);
  }
  const handleCreated = (event) => {
    getData();
  }
  const handleRemove = (event) => {
    getData();
  }
  useEffect(() => {
    favoritesServices.removeAllListeners();
    favoritesServices.on('created', handleCreated)
    favoritesServices.on('remove', handleRemove)
    return () => {
      // Clean up listeners
      favoritesServices.off('created', handleCreated);
      favoritesServices.off('remove', handleRemove);
    }
  }, []);

  React.useEffect(() => {
    getData()
  }, [items, token, institution]);
  React.useEffect(() => {
    if (sectionsDevices.length) {
      setSectionsDevicesAlarms([]);
      getAlarmsData()
    } else {
      setSectionsDevicesAlarms([]);
    }
  }, [sectionsDevices]);
  async function handleDelete(id) {
    setSectionsDevices(sectionsDevices.filter(e => e.id !== id));
    await favoritesServices.remove(id);
    setSelected(null);
  }
  return (
    <Grid direction={'column'} container display={'flex'} style={{ height: '100%' }}>
      <Helmet >
        <title>secciones</title>
        <meta name="description" content="Secciones" />
      </Helmet>
      <Grid item xs='auto' style={{ width: '100%' }}>
        <Grid item xs={12} container width={'100%'} style={{ display: 'flex', justifyContent: 'center', margin: '0px 0px', borderBottom: '0.5px solit gray' }}>
          <Grid item xs='auto' container flexDirection={'row'}>
            {/* <Grid item xs='auto'>
              <img src="assets/images/logo192.png" style={{ backgroundColor: 'transparent', height: 30, padding: 2 }} />
            </Grid> */}
            <Typography key={'registers'} paddingTop={3} paddingLeft={4} width={'100%'} fontSize={20} fontWeight='700'>
              {'Favoritos'}
            </Typography>
          </Grid>
          <Grid item xs='auto' justifyItems={'center'} p={'20px 0px'}>
            <CatalogsIconButton backgroundColor={'transparent'} model={favorites} icon='assets/icons/add.svg' size={20} />
          </Grid>
          <Grid item xs justifyItems={'center'} p={'3px 8px'}>
            {/* <InputBase
              onChange={(e) => setSearchText(e.target.value)}
              placeholder='Buscar...'
              inputProps={{ style: { backgroundColor: 'rgba(200,200,200,0.2', borderRadius: 8, padding: 8 } }}
            /> */}
          </Grid>

        </Grid>
      </Grid>
      <Grid item container xs alignContent={'flex-start'} style={{ width: '100%', overflow: 'scroll', padding: 0 }}>
        {
          sectionsDevices.map((sectionDevice, index) => {
            return (
              <Grid item xs={isMd ? 2 : window.screen.availWidth < 300 ? 12 : 6} key={index} position={'relative'} >
                <Box onClick={() => setSelected(sectionDevice)} p={1}>
                  <Box flexDirection={'row'} display={'flex'} width={'100%'}>
                    <Tooltip title={sectionDevice?.dispositivo?.vc_nombre} placement="top">
                      <Typography key={'registers'} p={1} paddingTop={0} width={'100%'} fontWeight='500' fontSize={14} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                        {sectionDevice?.dispositivo?.vc_nombre}
                      </Typography>
                    </Tooltip>

                  </Box>
                  <ItemDashboardFavorites stopData={!!selected} key={index} selected={sectionDevice.dispositivo} alarms={sectionsDevicesAlarms?.filter(e => e.id_dispositivoSeccion === sectionDevice.id_sectionDevice)} lite />
                </Box>
              </Grid>
            )
          })
        }

      </Grid>
      <Dialog
        open={!!selected}
        onClose={() => setSelected(null)}
        TransitionComponent={TransitionUp}
        fullScreen={!isMd}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          {selected?.dispositivo?.vc_nombre}
        </DialogTitle>
        <DialogContent>
          <Box width={400} maxWidth={'100%'}>
            <ItemDashboard key={'selected'} selected={selected?.dispositivo} alarms={sectionsDevicesAlarms?.filter(e => e.id_dispositivoSeccion === selected?.id_sectionDevice)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDelete(selected.id)}
          >
            {'Eliminar'}
          </Button>
          <Button
            onClick={() => setSelected(null)}
          >
            {'Cancelar'}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setSelected(null);
              history.push('/viewer?id=' + selected?.dispositivo?.id)
            }}
          >
            {'Ver'}
          </Button>

        </DialogActions>
      </Dialog>
    </Grid >
  );
}

export default DashboardView;