import transitions from '@material-ui/core/styles/transitions';
import { Box, Button, ButtonGroup, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSpeechRecognition, useSpeechSynthesis } from 'react-speech-kit';
import IALayer from './IALayer';
import 'moment/locale/es'
import IconMask from '../IconMask';
import { MicExternalOffOutlined, MicExternalOnOutlined, MicNoneOutlined, MicOff, MicOffOutlined, MicOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import app from '../../services';
import ChartLive from '../ChartLive';
const VoiceRecognition = ({ values }) => {
  const registersEvents = app.service('reports');
  const user = useSelector((state) => state.session.globalUser);
  const [value, setValue] = useState('');
  const [report, setReport] = useState(null);
  const speech = useSpeechSynthesis();

  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult: (result) => {
      setValue(result);
    }
  });
  async function getReport(type, data = []) {
    const measures = {};
    let iaGlobal = 'Se realiza análisis automatico de los registros de los sensores seleccionados.'
    data.forEach(item => {
      measures[item.vc_field] = {
        ...measures[item.vc_field],
        data: [...(measures[item.vc_field]?.data || []), item]
      }
    })
    const measuresRows = Object.keys(measures);
    console.log('measures', measures);
    measuresRows.forEach(key => {
      const measure = measures[key];
      const data = measure.data || [];
      let min = null;
      let max = null;
      let media = 0;
      let number = 0;
      let total = 0;
      let ia = ''
      const startDate = moment(([...data].pop())?.event?.createdAt);
      const endDate = moment(data[0]?.event?.createdAt);
      const startDateString = startDate.locale('es-MX').format('DD MMMM YYYY');
      const endDateString = endDate.locale('es-MX').format('DD MMMM YYYY');
      const lapse = endDate.diff(startDate, 'days');
      const lapseString = `${lapse}`.replace('-', '');
      data.forEach(register => {
        min = !!min && min?.nu_value < register.nu_value ? min : register;
        max = !!max && max?.nu_value > register.nu_value ? max : register;
        total = total + register.nu_value;
      })
      const variability = max.nu_value - min.nu_value;
      media = total / data.length;

      ia = `Se encontraron ${data.length} registros de ${min.vc_field} entre el ${startDateString} y el ${endDateString}, un reporte que comprende ${lapseString} días.`
      ia = ia + `\nCon un valor más bajo registrado de ${(min.nu_value).toFixed(2)} el cuál fue generado el pasado ${moment(min.event?.createdAt).locale('es-MX').format('DD MMMM YYYY')}, así mismo un valor máximo de ${max.nu_value} generado el pasado ${moment(max.event?.createdAt).locale('es-MX').format('DD MMMM YYYY')}, durante la revisión del algoritmo de inteligencia artificial se encuentra un promedio de ${media.toFixed(2)}`
      ia = ia + `\nLa variabilidad entre el valor maximo y minimo fue de ${variability} de su unidad de medida.`
      measures[key] = {
        ...measures[key],
        min,
        max,
        media,
        total: data.length,
        ia,
        title: min.vc_field
      }
      iaGlobal = iaGlobal + `\n\n${ia}`;
    })
    console.log('report', {
      ia: iaGlobal,
      measures: Object.values(measures)
    })
    return {
      ia: iaGlobal,
      measures: Object.values(measures)
    }
  }
  useEffect(() => {
    if (!listening) {
      const IA = IALayer(value);
      let text = '';
      IA.commands.map(item => {
        if (text === '') {
          text = 'Se reconocen los siguientes comandos de voz:'
        }
        text = text + `\n${item.raw}`;
      })
      IA.dates.map(item => {
        moment.locale('es');
        console.log('dates', item.value)
        let stringDate = moment(item.value).locale('es-MX').format('DD MMMM YYYY')
        if (!text.toLowerCase().includes('desde')) {
          text = text + `\nDesde el ${stringDate}`;
        } else {
          text = text + ` hasta el ${stringDate}, reporte de ${item.lapse} días`;
        }
      })
      console.log('tesxt', text)
      speech.cancel();
      speech.speak({ text: text })
      // getData();

    }
  }, [value, listening])
  // if (!browserSupportsSpeechRecognition) {
  //   return <span>Browser doesn't support speech recognition.</span>;
  // }
  async function getData() {
    let response = !!values ? { rows: values } : await registersEvents.find({
      query: {

      }
    });
    console.log('response', response);
    const report = await getReport('reporte total', response.rows);

    setReport(report)
    console.log('report.ia', report.ia);
    // speech.speak({ text: report.ia })
  }
  useEffect(() => {
    console.log('holaaa')
    speech.speak({ text: `Hola ${user.vc_nombre} en qué puedo ayudarte` })
  }, [])
  return ([
    <DialogTitle>
      <Box display={'flex'} justifyContent={'center'}>
        <IconMask color='gray' url={'/assets/icons/ia-tgone.svg'} size={100} />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Typography textAlign={'left'} style={{ fontSize: 16, fontWeight: 700 }}>
          {'By Dr. Valenzuela - Cardiología'}
        </Typography>
      </Box>

    </DialogTitle>,
    <DialogContent style={{ overflow: 'scroll' }}>
      {
        !report && <Typography>
          {'Puedes hace la petición de reportes automaticos'}
        </Typography>
      }
      {
        report?.measures?.map((item, index) => {
          return (
            <Box key={index} style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
              <Typography textAlign={'left'} style={{ fontSize: 16, fontWeight: 700 }}>
                {item.title}
              </Typography>
              <Divider />
              <Typography textAlign={'justify'}>
                {item.ia}
              </Typography>
              <Box key={'chartbox'} marginBottom={2} marginTop={2} style={{ borderRadius: 10, position: 'relative', overflow: 'hidden' }}>
                <ChartLive title={''} registers={item.data} />
              </Box>
            </Box>
          )
        })
      }
    </DialogContent>,
    <DialogActions style={{ borderTop: 'solid 0.5px rgba(200,200,200,0.3)' }}>
      <IconButton color='primary' variant={'contained'} onClick={() => {
        try {
          if (speech.speaking) {
            speech.cancel()
          } else {
            speech.speak({ text: report.ia })
          }
        } catch (error) {

        }
      }}>
        <img src='/assets/icons/speaking.svg' width={25} />
      </IconButton>
      <IconButton color='primary' variant={'contained'} onClick={() => {
        getData();
      }}>
        <img src='/assets/icons/ia-tgone.svg' width={25} />
      </IconButton>
      <Box display={'flex'} justifyContent={'center'}>
        {
          listening ?
            <IconButton variant={'contained'} onClick={stop}>
              <img src='/assets/icons/microphone.svg' width={25} />
            </IconButton>
            :
            <IconButton color='primary' variant={'contained'} onClick={() => {
              try {
                listen()
              } catch (error) {
                setValue(JSON.stringify(error));
              }
            }}>
              <img src='/assets/icons/microphone.svg' width={25} />
            </IconButton>
        }
      </Box>
    </DialogActions>
  ]
  );
};
export default VoiceRecognition;