import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import TimePickerMUI from '@mui/lab/TimePicker';
import { inputLabelClasses } from '@mui/material';
import moment from 'moment';

function TimePicker({ ...props }) {
  // Establecer un valor predeterminado (default value)
  
  const [value, setValue] = React.useState(props.defaultValue ? new Date(moment().format('YYYY-MM-DD ') + props.defaultValue + '00') : null);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePickerMUI
        closeOnSelect
        {...props}
        onChange={handleChange}
        value={value}
        ignoreInvalidInputs
        label={props.label}
        inputFormat={'HH:mm'}
        rifmFormatter={(value, format) => {
          if (!value) return '';
          const date = new Date(value);
          const hour = date.getHours().toString().padStart(2, '0');
          const minute = date.getMinutes().toString().padStart(2, '0');
          return `${hour}:${minute}`;
        }}
        renderInput={(params) => <TextField disabled  id={props.id} fullWidth variant='standard' {...params}
          InputLabelProps={{
            sx: {
              // set the color of the label when not shrinked
              color: "gray",
              padding: '5px 10px',
              [`&.${inputLabelClasses.shrink}`]: {
                // set the color of the label when shrinked (usually when the TextField is focused)
                padding: '0px 14px',
              }
            }
          }}
          InputProps={{ ...params.InputProps, disableUnderline: true, readOnly: true, placeholder: props.label, style: {padding: '6px 10px', backgroundColor: 'rgba(200,200,200,0.1)', boxShadow: '0px 0px 2px gray', borderRadius: 10 } }}
        />}
      />
    </LocalizationProvider>
  );
}

export default TimePicker;
