import React from 'react';
import styled from 'styled-components';

const Mask = styled('div')`
  box-sizing: border-box;
  height: ${props => props.size || '35'}${props => {return props.um || 'px'}};
  width: ${props => props.size || '35'}${props => {return props.um || 'px'}};
  padding: 0px;
  background-color: ${props => props.color || 'gray'};
  background-size: ${props => props.size || '35'}${props => {return props.um || 'px'}} ${props => props.size || '35'}${props => {return props.um || 'px'}};
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${props => props.url});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
const IconMask = (props) => {
  return (
    <Mask {...props} />
  );
};

export default IconMask;
