import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { common } from '../../repositories';
import useMeasure from 'react-use-measure'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TransitionUp } from '../../common/TransitionComponents';
import Loading from '../../common/Loading';
import ReactToPrint from "react-to-print";

import Forms from '../forms/Forms';
import { useSelector } from 'react-redux';
import PrintForms from '../../common/PrintForms';
import moment from 'moment';
import app from '../../services';
import IconMask from '../IconMask';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function TableCustom({ p, models = [], and = [], iconConfig, icon = '/assets/icons/connect.svg', display = 'table', mode = 'auto', path, variables = {}, tableToolBar, customForm }) {
  let componentRef = React.useRef();
  const user = useSelector((state) => state.session.globalUser);
  const [state, setState] = React.useState(variables);
  const [errorItems, setErrorItems] = React.useState(null);
  const history = useHistory();
  const catalogs = useSelector((state) => state.catalogs.persistent);
  const token = useSelector((state) => state.session.token);
  const institution = useSelector((state) => state.session.institution);
  const arrayModels = Object.values(models);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [model, setModel] = React.useState(arrayModels[0] || null);
  const [rows, setRows] = React.useState([]);
  const [editItem, setEditItem] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [limit, setLimit] = React.useState(50);
  const [ref, bounds] = useMeasure()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const services = !!(model?.service) ? app.service(model?.service) : null;
  const [searchFields, setSearchFields] = React.useState(model?.searchFields || []);


  const getData = async (model, silent) => {
    !silent &&
      setLoading(true);
    if (mode === 'fixed') {
      const filtered = rows.filter(e => JSON.stringify(e).toLowerCase().search(searchText.toLowerCase()) > -1)
      setLoading(false);
      setRows(filtered || []);
    } else {
      await app.reAuthenticate()
      try {
        console.log('busqueda', institution.vc_nombre, model?.and)

        const searchFieldsFiltered = searchFields.filter(e => (e.default || e.active) && e.type === 'text');

        const filtersDefault = [];

        model?.defaultSystem?.map((item, index) => {
          switch (true) {
            case (item.param === 'institution'): {
              filtersDefault.push({
                [item.vc_field]: institution[item.key]
              })
              break;
            }
            case (item.param === 'user'): {
              filtersDefault.push({
                [item.vc_field]: user[item.key]
              })
              break;
            }
            case (item.type === 'static'): {
              filtersDefault.push({
                [item.vc_field]: item.value
              })
              break;
            }
            default: { }
          }
        })
        const and = [...model?.and || [], ...filtersDefault]
        // const response = await app.service(model.service).find({
        //   query: {
        //     ...model?.include?.length ? {
        //       include: model?.include
        //     } : {},
        //     $limit: rowsPerPage || 30,
        //     $skip: rowsPerPage * page || 0,
        //     ...and.length ? {
        //       $and: and || []
        //     } : {},
        //     ...searchFieldsFiltered.length ? {
        //       $or: searchFieldsFiltered.map((searchField) => {
        //         return {
        //           [searchField.id]: {
        //             $like: '%' + searchText + '%'
        //           }
        //         }
        //       })
        //     } : {}
        //   }
        // });


        let response = {};
        console.log('search', searchText);
        const params = {
          ...model?.include?.length ? {
            include: model?.include
          } : {},
          $limit: rowsPerPage || 30,
          $skip: rowsPerPage * page || 0,
          ...and.length ? {
            $and: and || []
          } : {},
          ...searchFieldsFiltered.length ? {
            $or: searchFieldsFiltered.map((searchField) => {
              return {
                [searchField.id]: {
                  $like: '%' + searchText + '%'
                }
              }
            })
          } : {}
        };
        var baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3030';
        console.log('query ---->', JSON.stringify(params));
        const url = `${baseURL}/${model.service}?dataString=${JSON.stringify(params)}`;
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        // Verificar si la respuesta es exitosa (código de estado 2xx)
        if (res?.ok) {
          // Obtener la data
          response = await res?.json();
          // Manejar la data
          console.log('response', response);
        } else {
          // Manejar errores de la solicitud
          console.error('Error al realizar la solicitud:', res?.status, res?.statusText);
        }
        console.log('response?.data', response);
        setLoading(false);
        setRows(response?.data || []);
        setTotal(response?.total || 0);
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    }

  }


  const handleObserverCreated = (item) => {
    // console.log('product added', item);
    getData(model, true);
  }

  const handleObserverUpdated = (item) => {
    // console.log('product updated', item);
    getData(model, true);
  }
  React.useEffect(() => {
    if (!!services) {
      // services.off('created');
      services.on('created', handleObserverCreated);
      // services.off('updated');
      services.on('updated', handleObserverUpdated);
    }
    return () => {
      // Clean up listeners
      services.off('created', handleObserverCreated);
      services.off('updated', handleObserverUpdated);
    }
  }, [])
  React.useEffect(() => {
    setPage(0)
    setRows([]);
    setTotal(0);
    setSearchFields(model?.searchFields || []);
  }, [model])
  React.useEffect(() => {
    !model && setModel(models[0] || null);
  }, [models])
  React.useEffect(() => {
    console.log('data', user?.vc_nombre, institution?.vc_nombre)
    if (!!token && !!model) {
      getData(model);
    } else {
      setRows([]);
      setTotal(0);
      setPage(0);
    }
  }, [searchFields, page, searchText, token, model, user, institution])
  const onSave = async (item, model, editItem) => {
    if (mode === 'fixed') {
      let newData = [];
      if (item.id) {
        newData = rows.map(e => { return e.id === item.id ? item : e });
      } else {
        newData = [...rows, item]
      }
      setRows(newData);
    } else {
      // console.log('item save', item)

      try {
        if (item.id || editItem.id) {
          console.log('item -------->', item);
          await services.update(item.id || editItem.id, { ...editItem, ...item, ...model.defaultValues || {} })
          getData(model);
        } else {
          const saved = await services.create({ ...item, ...model.defaultValues || {} })
          console.log('saved', saved)
          getData(model);
        }
        setEditItem(null);
        setSelected([]);
      } catch (error) {
        console.log('error', error.errors);
        setErrorItems({ title: '¡Ups!', messages: 'No se ha podido guardar el registro', message: error?.errors?.map(e => `${e.message}\n`) })
      }
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const getLabel = ((item = {}, relation) => {
    let label = '';
    relation?.fieldsDisplay.map((key, index) => {
      label = `${label}${(relation?.objectPath ? (item[relation?.objectPath] || {})[key] || '' : item[key]) || ''}${relation?.fieldsDisplaySeparators[index] || ''}`
    });
    return label;
  })
  const getDataDefault = (modelAction) => {
    const defaultValues = {};
    // console.log('modelAction', modelAction);

    (modelAction?.columns || []).filter(e => !!e.value).map(item => {
      // console.log('columns', item, [item.value.type]);
      let value = null;
      switch (item.value.type) {
        case ('model'): {
          value = model[item.value.keyPath];
          break;
        }
        case ('user'): {
          console.log('user', user);

          value = user[item?.value?.keyPath];
          break;
        }
        default: value = null;
      }
      defaultValues[item.id] = value;
    })
    console.log('default', defaultValues);
    return defaultValues;
  }

  const TableToolBar = tableToolBar || EnhancedTableToolbar;

  return (
    <Box p={p} style={{ width: '100%', height: '100%' }}>
      <Paper ref={ref} style={{ width: '100%', height: '100%', borderRadius: 10, boxShadow: '0px 0px 3px gray' }}>
        <Grid container flexDirection={'column'} display={'flex'} height={'100%'} overflow='hidden'>
          <Grid item xs>
            <TableToolBar
              onDelete={() => {

                if (mode === 'fixed') {
                  setRows(rows.filter(e => !selected.includes(e)))
                }
                setSelected([]);
                setEditItem(null);
                selected.map((item) => {
                  // db.child('hospital').child('catalogs').child('articles').child(item.id).remove();
                })
              }}
              model={model}
              models={arrayModels || []}
              onSelectModel={(m) => setModel(m)}
              delaySearch={50}
              onSearch={(e) => setSearchText(e)} searchText={searchText} selected={selected} setEditItem={setEditItem} title={model?.title || ''} numSelected={selected.length} />

            <TableContainer sx={{ maxHeight: bounds.height - (116), maxWidth: bounds.width, position: 'relative' }} >
              <Table
                dense={'true'}
                stickyHeader
                // sx={{ width: '100%' }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                {
                  display !== 'item' &&
                  <EnhancedTableHead

                    headCells={(model?.columns || []).filter(e => !e.hidden)}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                }
                <TableBody
                  {...display === 'item' ? {
                    style: { justifyContent: 'flex-start', display: 'flex', flexWrap: 'wrap', flexDirection: isMd ? 'row' : 'row' }
                  } : {}}
                >
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {common.stableSort(rows, common.getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      if (display === 'item') {
                        // console.log('model focus', model)
                        const iconCustom = iconConfig?.path ? `/assets/icons/${(row[iconConfig?.path] || {})[iconConfig?.field]}` : null;
                        // console.log('iconConfig', row, iconConfig, iconCustom)
                        return (
                          <Grid item key={index} width={'auto'}>
                            <Box p={2} justifyContent={'center'} display='flex' width={150}>
                              <Paper
                                onDoubleClick={(e) => {

                                  e.stopPropagation();
                                  setSelected([row]);
                                  if (model.link) {
                                    history.push(model.link + '?id=' + row.id)
                                  } else {
                                    setEditItem(row);
                                  }
                                }}
                                onClick={(event) => handleClick(event, row)}
                                style={{ borderRadius: '50%', boxShadow: isItemSelected ? '0px 0px 10px gray' : '0px 0px 5px grey', padding: 16 }}>
                                <IconMask color='grey' url={iconCustom || icon} size={60} />
                              </Paper>
                            </Box >
                            {
                              !!model?.focus ?
                                <Box p={1} flexDirection={'column'} display={'flex'} width={150} overflow={'hidden'}>
                                  {model?.focus.map((f, index) => {
                                    // console.log('focus', f, row)
                                    return (
                                      <Typography variant={f.variant} key={index} alignSelf={'center'} textAlign={'center'} fontWeight={f.fontWeight || undefined}>
                                        {getLabel(row[f.path], f)}
                                      </Typography>
                                    )
                                  })}
                                </Box>
                                :
                                <Box width={150} overflow={'hidden'}>
                                  <Typography style={{ fontWeight: 700, fontSize: '1rem', textAlign: 'center' }}>
                                    {row.vc_nombre}
                                  </Typography>
                                  <Typography style={{ fontWeight: 700, fontSize: '0.8rem', textAlign: 'center' }}>
                                    {row.tipoActivo?.vc_nombre}
                                  </Typography>
                                </Box>
                            }
                          </Grid>
                        )
                      }
                      return (
                        <TableRow
                          hover
                          onDoubleClick={(e) => {
                            e.stopPropagation();
                            setSelected([row]);
                            setEditItem(row);
                          }}
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox"
                            style={{ borderBottom: '1px solid rgba(200,200,200,0.5)' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          {
                            (model?.columns || []).filter(e => !e.hidden).map((column, index) => {
                              let display = row[column.id] || '-';
                              const catalog = catalogs[column.relation?.vc_nameReducer];
                              if (!!catalog && !!column.relation) {
                                try {
                                  const relationItem = catalog.data.find(e => e[column.relation?.keyTable] === display);
                                  if (!!relationItem)
                                    display = getLabel(relationItem, column?.relation);
                                } catch (error) {
                                  console.log('error', error);
                                }
                              } else if (`${column.id}`.startsWith('dt_')) {
                                const dateString = moment(display)
                                display = dateString.isValid() ? dateString.format('DD-MM-YYYY') : '-'
                              }
                              return (
                                <TableCell key={index} padding="normal" align={column.numeric ? 'right' : 'left'}
                                  style={{ borderBottom: '1px solid rgba(200,200,200,0.5)' }}
                                >{display}</TableCell>
                              )
                            })
                          }
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </TableContainer>

          </Grid>
          <Grid item xs position={'relative'}>
            {
              loading &&
              <Box style={{ position: 'relative', top: 0, left: 0, width: '100%' }}>
                <Loading />
              </Box>
            }
          </Grid>
          <Grid item xs={'auto'}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ boxShadow: '0px -2px 2px rgba(200,200,200,0.5)' }}
            />
          </Grid>
        </Grid>
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      {
        customForm ? customForm({ editItem, setEditItem, setSelected, getDataDefault, path, onSave }) :
          <Dialog
            open={!!editItem}
            onClose={() => {
              setEditItem(null);
              setSelected([]);
            }}
            fullScreen={fullScreen || model?.fullScreen || !isMd}
            transitionDuration={400}
            TransitionComponent={TransitionUp}
            PaperProps={{ style: { borderRadius: 10 } }}
          >
            <DialogTitle>
              {!!editItem?.id ? 'Editar ' + model?.title || '' : 'Agregar ' + model?.title || ''}
            </DialogTitle>
            <DialogContent style={{ minWidth: 300 }}>
              <Stack
                spacing={3}          >
                <Forms model={model?.modelAction || model} item={{ ...editItem, ...getDataDefault(model?.modelAction || model) }} path={path || ''} />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' onClick={() => {
                setEditItem(null);
                setSelected([]);
              }}>
                {'Cancelar'}
              </Button>
              <Button variant='outlined' onClick={() => {
                const obj = {};
                (model?.modelAction?.columns || model?.columns || []).map((field, index) => {
                  const id = (model.id || '') + field.id;
                  const doc = document.getElementById(id);
                  const value = document.getElementById(id)?.value;
                  console.log('doc', doc, value, field.id);
                  if (value !== undefined && value !== '' && value !== null) {
                    obj[field.id] = value;
                  } else if (field.id !== 'id') {
                    obj[field.id] = null;
                  }
                })


                const defaultObj = {};
                model?.defaultSystem?.map((item, index) => {
                  switch (true) {
                    case (item.param === 'institution'): {
                      defaultObj[item.vc_field] = institution[item.key];
                      break;
                    }
                    case (item.param === 'user'): {
                      defaultObj[item.vc_field] = user[item.key];
                      break;
                    }
                    case (item.type === 'static'): {
                      defaultObj[item.vc_field] = item.value;
                      break;
                    }
                    default: { }
                  }
                })
                const val = { ...obj, ...defaultObj };
                const valEdit = { ...editItem, ...defaultObj };
                const requiredFilter = model.columns.filter(e => e.required && (val[e.id] === null || val[e.id] === undefined))
                console.log('guardar', val);
                if (requiredFilter.length > 0) {
                  setErrorItems({
                    title: '¡Ups!',
                    message: 'Los siguientes campos son obligatorios:',
                    fields: requiredFilter
                  })
                } else {
                  onSave(val, model, valEdit);
                }
              }}>
                {mode === 'auto' ? 'Guardar' : 'Agregar'}
              </Button>

            </DialogActions>
            <Dialog
              open={!!errorItems}
              style={{
                minWidth: 300
              }}
            >
              <DialogTitle>
                {errorItems?.title}
              </DialogTitle>
              <DialogContent>
                <Typography key={'error'} marginBottom={1}>
                  {errorItems?.message}
                </Typography>
                {/* <Paper style={{ padding: 8 }}> */}
                {
                  errorItems?.fields?.map((item, index) => {
                    return (
                      <Typography key={index}>
                        {item.label}
                      </Typography>
                    )
                  })
                }
                {/* </Paper> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorItems(null)}>
                  {'Aceptar'}
                </Button>
              </DialogActions>
            </Dialog >
          </Dialog>
      }
      
    </Box >
  );
}
