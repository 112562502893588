import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconMask from '../IconMask';
import SearchSelect from '../forms/SearchSelect';
import { Box, Dialog, Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import Forms from '../forms/Forms';
import app from '../../services';
import moment from 'moment';

export default function EditableModel({ model, mode, item, firstButtons, endButtons, closeButton, withOutTitle, onSave = () => { }, onClose = () => { } }) {
  const institution = useSelector((state) => state.session.institution);
  const user = useSelector((state) => state.session.globalUser);
  const [errorItems, setErrorItems] = React.useState(null);
  const catalogs = useSelector((state) => state.catalogs.persistent);
  const services = !!(model?.service) ? app.service(model?.service) : null;
  const [editItem, setEditItem] = React.useState(item);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    setEditItem({ ...editItem, id_institucion: institution?.id })
  }, [institution])
  React.useEffect(() => {
    return (() => {
      setEditItem(null);
    })
  }, [])
  const getDataDefault = (modelAction) => {
    const defaultValues = {};
    // console.log('modelAction', modelAction);

    (modelAction?.columns || []).filter(e => !!e.value).map(item => {
      // console.log('columns', item, [item.value.type]);
      let value = null;
      switch (item.value.type) {
        case ('model'): {
          value = model[item.value.keyPath];
          break;
        }
        case ('user'): {
          // console.log('user', user);

          value = user[item?.value?.keyPath];
          break;
        }
        default: value = null;
      }
      defaultValues[item.id] = value;
    })
    // console.log('default', defaultValues);
    return defaultValues;
  }

  const onSaveHandler = async (item, model, editItem) => {
    console.log('item save', item, editItem)
    try {
      if (!!item?.id || !!editItem?.id) {
        console.log('edito')
        const res = await services.update(item.id || editItem?.id, { ...editItem, ...item, ...model.defaultValues || {} })
        !!onSave && onSave(res);
        !closeButton && onClose()
      } else {
        console.log('creo')
        const res = await services.create({ ...item, ...model.defaultValues || {} })
        console.log('res', res);
        !!onSave && onSave(res);
        setEditItem({ ...editItem, id: res.id })
        !closeButton && onClose()
      }
    } catch (error) {
      console.log('error', error);
      setErrorItems({ title: '¡Ups!', fields: error?.errors?.length ? error?.errors?.map(e => { return { label: e.message } }) : [{ label: error?.message || 'Intenta de nuevo' }] })
    }

  }
  const agregar = !!item?.id || !!editItem?.id;
  console.log('edit item', agregar, item?.id || editItem?.id);
  return (
    [
      !withOutTitle &&
      <DialogTitle>
        {!!editItem?.id ? 'Editar ' + model?.title || '' : 'Agregar ' + model?.title || ''}
      </DialogTitle>,
      <DialogContent style={{ minWidth: 300, padding: 8 }}>
        <Stack
          spacing={2}>
          <Forms model={model?.modelAction || model} item={{ ...item, ...editItem, ...getDataDefault(model?.modelAction || model) }} path={''} />
        </Stack>
      </DialogContent>,
      <DialogActions style={{justifyContent:'flex-end', overflow: 'hidden', padding: '8px 16px'}}>
        {firstButtons || null}
        {closeButton ||
          <Button fullWidth key={'cancel'}  variant='outlined' onClick={() => {
            setEditItem(null);
            onClose();
          }}>
            {'Cancelar'}
          </Button>}
        <Button fullWidth key={'save'} variant='outlined' onClick={() => {
          // console.log('entro ----->', model)
          const obj = {};
          const requiredFilter = [];
          (model?.modelAction?.columns || model?.columns || []).map((field, index) => {
            ([...field.fields || [field]]).map((innerField) => {
              const id = (model.id || '') + innerField.id;
              const value = document.getElementById(id)?.value;
              // console.log('doc', doc, value, field.id);
              if (value !== undefined && value !== '' && value !== null) {
                if (`${innerField.id}`.startsWith('dt_')) {
                  console.log('value date', value);
                  obj[innerField.id] = moment(value, `${value}`.includes('/') ? 'DD/MM/YYYY' : undefined).format(`${value}`.includes(':') ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD');
                } else {
                  obj[innerField.id] = value;
                }
              } else if (innerField.id !== 'id') {
                innerField?.required && requiredFilter.push(innerField);
                obj[innerField.id] = null;
              } else {
                innerField?.required && requiredFilter.push(innerField);
              }
            })
          })
          const defaultObj = {};
          model?.defaultSystem?.map((item, index) => {
            switch (true) {
              case (item.param === 'institution'): {
                defaultObj[item.vc_field] = (institution || {})[item.key] || undefined;
                break;
              }
              case (item.param === 'user'): {
                defaultObj[item.vc_field] = (user || {})[item.key] || undefined;
                break;
              }
              case (item.type === 'static'): {
                defaultObj[item.vc_field] = item?.value || undefined;
                break;
              }
              default: { }
            }
          })
          const val = { ...obj, ...defaultObj };
          // const requiredFilter = model.columns.filter(e => e.required && (val[e.id] === null || val[e.id] === undefined || ((e.fields || ).filter(ei => ei.required && (val[ei.id] === null || val[ei.id] === undefined)))

          if (requiredFilter.length > 0) {
            setErrorItems({
              title: '¡Ups!',
              message: 'Los siguientes campos son obligatorios:',
              fields: requiredFilter
            })
          } else {
            onSaveHandler(val, model, editItem);
          }
        }}>
          {mode === 'auto' || (!!item?.id || !!editItem?.id) ? 'Guardar' : 'Agregar'}
        </Button>
        {endButtons || null}
      </DialogActions >,
      <Dialog
        key={'errorDialog'}
        open={!!errorItems}
      >
        <DialogTitle>
          {errorItems?.title}
        </DialogTitle>
        <DialogContent>
          <Typography key={'error'}>
            {errorItems?.message}
          </Typography>
          {
            errorItems?.fields?.map((item, index) => {
              return (
                <Typography key={index}>
                  {item.label}
                </Typography>
              )
            })
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorItems(null)}>
            {'Aceptar'}
          </Button>
        </DialogActions>
      </Dialog >
    ]
  );
}