(function () {

    let backEndURL = '';
    let adminBackEndUrl = '';
    let redirectSafe = null;
    // mode - Especifica el ambiente de desarrollo a ejecutar.
    const mode = 'prod';
    switch(mode){
        case 'dev':{
            backEndURL = 'https://development-dot-think-medical.appspot.com';
            adminBackEndUrl = 'https://development-dot-think-memberships.appspot.com';
            redirectSafe = null;
            break;
        }
        case 'prod':{
            backEndURL = 'https://api.thin-k.mx';
            adminBackEndUrl = 'https://admin.thin-k.mx';
            redirectSafe = 'https://salud.thin-k.mx';
            break;
        }
        case 'local':{
            backEndURL = 'http://localhost:8080';
            adminBackEndUrl = 'http://localhost:8080';
            redirectSafe = null;
            break;
        }
    }

    const themeSelected = window.amplify.store('theme') || null;
    var initialRoute = 'login';
    var dt_actualizacion = '2018-10-10 08:00:00 0000';
    var colors = {
        textPrint: 'black',
        green: "#157717",
        yellow: "#dbd413",
        red: "rgba(181, 80, 65,1)",
        greenLight: "rgb(10, 155, 0)",
        yellowLight: "rgb(216, 202, 0)",
        redLight: "rgba(181, 65, 65,1)",
        orange: "orange",
        menu: "rgb(54, 124, 86)",
        itemSelected: themeSelected ? themeSelected.primary[300] : "rgb(54, 124, 86)",
        itemSelectedLight: themeSelected ? themeSelected.primary[300] : "rgb(54, 124, 86)",
        grayTable: "#e8ebed",
        white: "#ffffff",
        whiteTableView: "rgb(230,230,230)",
        tableSectionsHeader: "#000000",
        black: "rgb(250,250,250)",
        blackTrans: "rgba(0,0,0,0.8)",
        headers: "rgba(0,0,0,0.6)",
        headersColor: "orange",
        subHeders: "rgba(0,0,0,0.8)",
        itemFill: "white",
        textFields: "rgba(255,255,255,0.8)",
        titleBlue: "blue",
        title: "rgba(0,0,0,1)",
        subTitle: "gray",
        itemLabel: "#1584d3",
        backgroundSubMenu: 'rgb(240,240,240)',
        backgroundHelper: 'transparent',
        textArea: 'black'
    };
    var themes = {
        helperMenuStyle: {
            textAlign: 'right',
            borderRadius: 0,
            // height: this.state.opcionActualModulo === 'buscar' ? 43 : 35,
            margin: 0,
            boxShadow: '1px 4px 5px rgba(0, 0,0, 0.3)',
            backgroundColor: colors.backgroundSubMenu
        }
    }
    
    window.isMobile = window.innerWidth < 650 ? true : false;
    window.w = window.innerWidth;
    window.h = window.innerHeight;
    window.sidebarWidth = window.innerWidth < 650 ? window.innerWidth - 100 : window.innerWidth * 0.25;
    window.menuWidth = 300;
    window.colors = colors;
    window.themes = themes;
    window.themeSelected = themeSelected;
    window.backEndURL = backEndURL;
    window.adminBackEndUrl = adminBackEndUrl;
    window.initialRoute = initialRoute;
    window.redirectSafe = redirectSafe;
    window.dt_actualizacion = dt_actualizacion;
}());
