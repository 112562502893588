import React from "react";
import './BatteryIndicator.css';

export default function BatteryIndicator({ size = 60, color = '#00BB00', value = 11.9 }) {
    let values = ['low', 'med', 'full'];
    let colorBack = 'red';
    switch (true) {
        case (value >= 11.9): {
            values = ['low', 'med', 'full']
            colorBack = color;
            break;
        }
        case (value < 11.9 && value >= 11.2): {
            values = ['low', 'med']
            colorBack = '#BBBB00';

            break;
        }
        case (value < 11.2 && value > 10.5): {
            values = ['low']
            colorBack = '#BB0000';
            break;
        }
        default: {
            values = [] 
        }
    }
    return (
        <div style={{ width: size * 1, height: size * 0.6, padding: 8 }}>
            <div className="grid-container-battery">
                <div className="left-component-battery" style={{ borderRadius: size * 0.1, borderWidth: size * 0.02, display: 'flex', flexDirection: 'row', padding: size * 0.02 }}>
                    <div style={{ borderRadius: size * 0.06, width: '100%', height: '100%', overflow: 'hidden', borderWidth: size * 0.02, display: 'flex', flexDirection: 'row' }}>
                        {
                            values.map((item, index) => {
                                return (
                                    <div key={index} className="child-battery" style={{ paddingRight: index < 2 ? size * 0.02 : 0, height: '100%' }}>
                                        <div  style={{  height: '100%', backgroundColor: colorBack }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="right-component-battery" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ width: '100%', height: '33%', backgroundColor: 'white', borderTopRightRadius: size*0.04, borderBottomRightRadius: size*0.04 }} />
                </div>
            </div>
        </div>
    )
}