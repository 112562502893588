import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import sessionReducer from '../session';
import studiesReducer from '../studies';
import modulesReducers from '../modules';
import catalogsReducers from '../catalogs';

const reducers = combineReducers({
  session: sessionReducer,
  studies: studiesReducer,
  modules: modulesReducers,
  catalogs: catalogsReducers
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export default store;