import { createSlice } from '@reduxjs/toolkit'

export const modulesSlice = createSlice({
  name: 'modules',
  initialState: {
    modulo: null,
    subModulo: null,
  },
  reducers: {
    setModule: (state, action) => {
      state.modulo = {
        ...action.payload,
        subModulo: (action.payload?.subModules || [])[0] || action.payload
      };
    },
    setSubModule: (state, action) => {
      state.modulo.subModulo = state.modulo.subModules.find(e => e.path === action.payload);
    }
  },
})

export const { setModule, setSubModule } = modulesSlice.actions;

export default modulesSlice.reducer;