import { xml2json } from "xml-js";

function getOrderData(data) {
    const json = { ...data.attributes || {} };
    const keys = {};
    data.elements?.map(e => {
        keys[e.name] = { elements: [...(keys[e.name] || []), e.name] }
    })
    console.log('elements', data.elements.length);

    data.elements?.map(e => {
        // console.log('element', e.name);
        const type = keys[e.name];
        if (type.elements.length > 1) {
            const obj = getOrderData(e);
            json[e.name] = [...(json[e.name] || []), obj]
        } else {
            const obj = getOrderData(e);
            json[e.name] = obj;
        }
    })
    console.log('json', json)
    return json;
}

function parseXmlToJson(xml) {
    const parsed = xml2json(xml, { compact: false, spaces: 2 });
    return getOrderData(parsed);
}
const formatter = {
    parseXmlToJson,
}
export default formatter;