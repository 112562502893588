import React from "react";
import { Box } from "@mui/material";
import IconMask from "../IconMask";

export default function FieldContainerIcon({ field, icon, ...props }) {

    return (
        <Box key={'range'} display={"flex"} width={'100%'}>
            {
                !!icon &&
                <Box key={'icon'} margin={0.5} marginTop={2.5} marginRight={2} display='flex' flexDirection={"column"} justifyContent='center' ><IconMask color='grey' url={`assets/icons/${icon || 'edit.svg'}`} size={25} /></Box>
            }
            <Box style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                {props.children}
            </Box>
        </Box>
    );
}