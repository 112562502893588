import React, { useState } from 'react';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export default function DateRangePicker({ onChange = () => { }, onClose = () => { }, value = [null, null], limits = [null, null] }) {
  console.log('valuem', value);
  const [defaultValue, setDefault] = React.useState(value || [null, null]);
  console.log('limits', limits);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDateRangePicker
        // minDate={limits[0] || undefined}
        // maxDate={limits[1] || undefined}
        displayStaticWrapperAs="desktop"
        value={defaultValue} 
        onChange={onChange}
        onClose={onClose}
        renderInput={(startProps, endProps) => (
          <>
            <input {...startProps} placeholder="Start Date" />
            <input {...endProps} placeholder="End Date" />
          </>
        )}
      />
    </LocalizationProvider>
  );
};
