import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';
import defaultCatalogs from '../../constants/defaultCatalogs.json';
export const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState: {
    persistent: defaultCatalogs,
  },
  reducers: {
    updateCatalogs: (state, action) => {
      console.log('action?.payload?.name', action?.payload?.name)
      const catalogEdit = state.persistent[action?.payload?.name] || { name: action?.payload?.name, data: [], lastUpdate: moment('2022-01-01') * 1 };
      const newData = {};
      state.persistent[action?.payload?.name]?.data?.map(e => {
        newData[e.id] = e;
      });
      action?.payload?.data?.map(e => {
        newData[e.id] = e;
      });

      const newState = {
        ...state.persistent,
        [action?.payload?.name]: {
          ...catalogEdit,
          data: Object.values(newData)
        }
      }
      state.persistent = newState;
    }
  },
})

export const { updateCatalogs } = catalogsSlice.actions;

export default catalogsSlice.reducer;