import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';  // or var ReactECharts = require('echarts-for-react');
import * as echarts from 'echarts';
import 'echarts/extension/bmap/bmap';
import useMeasure from 'react-use-measure';
import { useTheme } from '@mui/styles';
import { Paper } from '@mui/material';
import data from './linesData.json';

export default function MapAnimated() {
    const [refChart, boundInner] = useMeasure()
    const [busLines, setBusLines] = useState([]);
    const getLines = async () => {
        let hStep = 300 / (data.length - 1);
        let busLinesEdit = data.map((busLine, idx) => {
            let prevPt = [];
            let points = [];
            for (let i = 0; i < busLine.length; i += 2) {
                let pt = [busLine[i], busLine[i + 1]];
                if (i > 0) {
                    pt = [prevPt[0] + pt[0], prevPt[1] + pt[1]];
                }
                prevPt = pt;
                points.push([pt[0] / 1e4, pt[1] / 1e4]);
            }
            return {
                coords: points,
                lineStyle: {
                    normal: {
                        color: echarts.color.modifyHSL('#5A94DF', Math.round(hStep * idx))
                    }
                }
            };
        });
        console.log('buslines', busLinesEdit);
        setBusLines(busLinesEdit);

    }
    useEffect(() => {
        getLines();

    }, [])
    const elem = document.getElementsByClassName('anchorBL');
    // console.log('element', elem);
    elem[0]?.setAttribute('hidden', true);
    elem[1]?.setAttribute('hidden', true);
    return (
        <Paper ref={refChart} style={{ display: 'flex', borderRadius: 10, height: '100%', overflow: 'hidden' }}>
            {
                busLines.length > 1 && boundInner.width > 0 && boundInner.height > 0 &&
                <ReactECharts
                    style={{ width: boundInner.width, height: boundInner.height }}
                    option={{
                        backgroundColor: 'transparent',
                        // geo: {
                        //     center: [116.46, 39.92],
                        //     zoom: 10,
                        //     map: 'world',
                        //     roam: true,
                        //     silent: true,
                        //     itemStyle: {
                        //       color: 'transparent',
                        //       borderColor: 'rgba(255,255,255,0.1)',
                        //       borderWidth: 1
                        //     }
                        //   },
                        bmap: {
                          center: [116.46, 39.92],
                          zoom: 10,
                          roam: true,
                          mapStyle: {
                            styleJson: [
                              {
                                featureType: 'water',
                                elementType: 'all',
                                stylers: {
                                  color: '#031628'
                                }
                              },
                              {
                                featureType: 'land',
                                elementType: 'geometry',
                                stylers: {
                                  color: '#000102'
                                }
                              },
                              {
                                featureType: 'highway',
                                elementType: 'all',
                                stylers: {
                                  visibility: 'off'
                                }
                              },
                              {
                                featureType: 'arterial',
                                elementType: 'geometry.fill',
                                stylers: {
                                  color: '#000000'
                                }
                              },
                              {
                                featureType: 'arterial',
                                elementType: 'geometry.stroke',
                                stylers: {
                                  color: '#0b3d51'
                                }
                              },
                              {
                                featureType: 'local',
                                elementType: 'geometry',
                                stylers: {
                                  color: '#000000'
                                }
                              },
                              {
                                featureType: 'railway',
                                elementType: 'geometry.fill',
                                stylers: {
                                  color: '#000000'
                                }
                              },
                              {
                                featureType: 'railway',
                                elementType: 'geometry.stroke',
                                stylers: {
                                  color: '#08304b'
                                }
                              },
                              {
                                featureType: 'subway',
                                elementType: 'geometry',
                                stylers: {
                                  lightness: -70
                                }
                              },
                              {
                                featureType: 'building',
                                elementType: 'geometry.fill',
                                stylers: {
                                  color: '#000000'
                                }
                              },
                              {
                                featureType: 'all',
                                elementType: 'labels.text.fill',
                                stylers: {
                                  color: '#857f7f'
                                }
                              },
                              {
                                featureType: 'all',
                                elementType: 'labels.text.stroke',
                                stylers: {
                                  color: '#000000'
                                }
                              },
                              {
                                featureType: 'building',
                                elementType: 'geometry',
                                stylers: {
                                  color: '#022338'
                                }
                              },
                              {
                                featureType: 'green',
                                elementType: 'geometry',
                                stylers: {
                                  color: '#062032'
                                }
                              },
                              {
                                featureType: 'boundary',
                                elementType: 'all',
                                stylers: {
                                  color: '#465b6c'
                                }
                              },
                              {
                                featureType: 'manmade',
                                elementType: 'all',
                                stylers: {
                                  color: '#022338'
                                }
                              },
                              {
                                featureType: 'label',
                                elementType: 'all',
                                stylers: {
                                  visibility: 'off'
                                }
                              }
                            ]
                          }
                        },
                        series: [
                          {
                            type: 'lines',
                            coordinateSystem: 'bmap',
                            polyline: true,
                            data: busLines,
                            silent: true,
                            lineStyle: {
                              // color: '#c23531',
                              // color: 'rgb(200, 35, 45)',
                              opacity: 0.2,
                              width: 1
                            },
                            progressiveThreshold: 500,
                            progressive: 200
                          },
                          {
                            type: 'lines',
                            coordinateSystem: 'bmap',
                            polyline: true,
                            data: busLines,
                            lineStyle: {
                              width: 0
                            },
                            effect: {
                              constantSpeed: 20,
                              show: true,
                              trailLength: 0.1,
                              symbolSize: 2
                            },
                            zlevel: 1
                          }
                        ]
                      }}
                    // notMerge={true}
                    // lazyUpdate={true}
                    theme={'dark'}
                    // onChartReady={this.onChartReadyCallback}
                    // onEvents={EventsDict}
                    opts={{renderer:'canvas',height: boundInner.height, width: boundInner.width}}
                />
            }
        </Paper>
    )
}