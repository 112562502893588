import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './reducers/rootReducer';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './i18n';
import config from './config';
const store = createStore(rootReducer)

ReactDOM.render(
      <React.StrictMode>
            <React.Suspense fallback={<div>...Loading</div>}>
                  <Provider store={store}>
                        {/* <I18nextProvider i18n={i18n}> */}
                              <Router>
                                    <App />
                              </Router>
                        {/* </I18nextProvider> */}
                  </Provider>
            </React.Suspense>
      </React.StrictMode>,

      document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();