import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import moment from 'moment';
import ReactApexChart from "react-apexcharts"
import config from './config';


const ChartLive = ({ title = '', registers = [] }) => {
    const [customConfig, setCustomConfig] = useState({ ...config, series: [] });

    useEffect(() => {
        const categories = [];
        const data = [];
        console.log('registers', registers)
        registers.map((item) => {
            if (item.nu_value) {
                data.push(item.nu_value)
                categories.push(moment(item.createdAt) * 1);
            }
        });
        console.log('registros data en medidor', data)
        setCustomConfig({ options: { ...config.options, xaxis: { type: 'datetime', categories: categories } }, series: [{ name: title, data: data }] })
    }, [registers])

    return (
            <ReactApexChart key={title} options={{...customConfig.options}} series={customConfig.series} type={config.options.chart.type} height={200} />
    )
}

export default ChartLive;