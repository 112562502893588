export const initState = null;

const userReducer = (state = initState, action) => {
      switch (action.type) {
            //Change character name
            case 'SET_USER':
                  window.amplify.store('token', action.payload.token || null)
                  return {
                        ...state || {},
                        ...action.payload
                  }
            case 'SET_INSTITUTION':
                  window.amplify.store('institution', action.payload || null)
                  return {
                        ...state || {},
                        institution: action.payload
                  }
            case 'SET_USER_SELECTED':
                  return {
                        ...state || {},
                        selected: action.payload
                  }
            case 'SET_USER_EXPEDIENT':
                  const newState = {
                        ...state,
                        selected: {
                              ...state.selected,
                              expediente: action.payload.data
                        }
                  }
                  if (action.payload.id_autorizacionCompartir && !!newState.dependientes.find(e => e.id_autorizacionCompartir === action.payload.id_autorizacionCompartir)) {
                        newState.dependientes.find(e => e.id_autorizacionCompartir === action.payload.id_autorizacionCompartir).expediente = action.payload.data;
                  } else {
                        newState.paciente.expediente = action.payload.data;
                  }
                  return {
                        ...newState
                  }
            case 'UNSET_USER':
                  return null;
            default:
                  return state
      }
}

export default userReducer;