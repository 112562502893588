import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../../App.css';
import { Box, Button, Divider, Grid, ListItem, ListItemText, Typography } from '@mui/material';
import firebase from '../../services/firebase';
import ModalItem from '../../components/ModalItem';
import ViewerLevel from './ViewerLevel';
import MarkerAnimate from './MarkerAnimate';
import dataDemo from './dataDemo.json';
import app from '../../services';
import ChartLive from '../ChartLive';
import moment from 'moment';
import GaugeChart from 'react-gauge-chart';
import GaugeLinear from '../GaugeLinear';
import Selector from './Selector';

function ViewersLevels({ selected, defaultMeasure }) {
  const [data, setData] = useState({});
  const [typesMeasures, setTypesMeasures] = useState(defaultMeasure ? [defaultMeasure] : []);
  const [measureSelect, setMeasureSelect] = useState(defaultMeasure || null);
  const [values, setValues] = useState([]);
  const registersEvents = app.service('events-registers');
  const events = app.service('events');
  const getData = async () => {
    console.log('measure request', measureSelect, {
      query: {
        ...!!measureSelect ? { vc_fieldName: (measureSelect).vc_fieldName } : {},
        selected,
        $limit: 100,
        $skip: 0,
        $where: { id_dispositivoArea: selected?.id },
        $sort: {
          createdAt: -1
        }
      }
    });

    if (!measureSelect) return;
   
    const response = await registersEvents.find({
      query: {
        ...!!measureSelect ? { vc_fieldName: (measureSelect).vc_fieldName } : {},
        selected,
        $limit: 100,
        $skip: 0,
        $where: { id_dispositivoArea: selected?.id },
        $sort: {
          createdAt: -1
        }
      }
    });
    console.log('get', response);
    const lastValue = ([...values].reverse()[0] || {}).nu_value;
    const range = (response.dispositivo?.tipo?.nu_max || 100) - (response.dispositivo?.tipo?.nu_min)
    let percent = lastValue / range;
    if (percent > 1) {
      percent = 1;
    }
    let max = 0;
    const valuesResponse = (response?.data || response?.rows || []).map(e => { return { ...e, timestamp: moment(e.createdAt) * 1 } }).sort(function (a, b) { return a.timestamp - b.timestamp }) || []
    valuesResponse.map(e => {
      if (e.nu_value > max && !response.dispositivo?.tipo?.nu_max) {
        max = e.nu_value;
      }
    })
    setData({ ...response, max, percent, values: (response?.data || response?.rows || []).map(e => { return { ...e, timestamp: moment(e.createdAt) * 1 } }).sort(function (a, b) { return a.timestamp - b.timestamp }) || [] })
    setValues(valuesResponse.map(e => { return { ...e, nu_value: e.nu_value.toFixed(response.dispositivo.tipo.nu_decimales || 0) } }));
  }

  const getTypes = async () => {
    const types = await app.service('custom-devices-sections').find({ query: selected });
    console.log('types', types);
    setTypesMeasures(types?.rows || []);
    if (!measureSelect) {
      console.log('types', types);
      setMeasureSelect((types?.rows || [])[0] || null);
    }
  }
  useEffect(() => {
    !!selected && !!measureSelect && getData();
    console.log('measureSelect', measureSelect);
  }, [selected, measureSelect])
  
  useEffect(() => {
    registersEvents.on('created', (item) => {
      console.log('register event added', item);
      getData();
    });
    if (!defaultMeasure)
      getTypes();

    // events.on('created', (item) => {
    //     console.log('event added', item);
    //     getData();
    // });
    return () => {
      registersEvents.removeAllListeners('created', () => { });
      registersEvents.removeListener('updated', () => { });

    }
  }, [])
  const lastValue = ([...values].reverse()[0] || {}).nu_value;
  const lastValueObject = ([...values].reverse()[0] || {});
  console.log('lastValue', lastValue);
  return (
    <Box p={2} style={{ height: 'auto' }}>

      <Typography key={'title'} variant='h5' p={1} textAlign='center'>
        {selected?.vc_nombre}
      </Typography>
      <Divider />
      <Typography key={'valores'} p={2} paddingLeft={3} fontWeight='700'>
        {'Valores:'}
      </Typography>
      <Box key={'selector'} marginBottom={2} style={{ borderRadius: 10, boxShadow: '0px 0px 5px gray', position: 'relative', overflow: 'hidden' }}>
        <Selector value={measureSelect} options={typesMeasures} onSelect={(item) => setMeasureSelect(item)} />
      </Box>
      <Box key={'indicator'} p={1} style={{ borderRadius: 10, boxShadow: '0px 0px 5px gray', position: 'relative', overflow: 'hidden' }}>
        <GaugeLinear height={15} value={lastValue} min={data.dispositivo?.tipo.nu_min || 0} max={data.max || data.dispositivo?.tipo.nu_max || 100} />
        <Typography
          key={'title'}
          style={{ width: '100%', textAlign: 'center', fontSize: '3rem', fontWeight: 700, zIndex: 1000, textShadow: '0px 0px 3px gray' }}
        >
          {(lastValue * 1 || 0).toFixed(data.dispositivo?.tipo?.nu_decimales || 0)}
          {` ${measureSelect?.medida?.vc_abreviatura || ''}`}
        </Typography>
        {/* <Typography key={'subtitle'} fontSize={'1.5rem'} fontWeight={'700'}
                    style={{ textShadow: '0 0 3px gray', textAlign: 'center' }}
                >
                    {measureSelect?.medida?.vc_abreviatura || ''}
                </Typography> */}
        <Typography key={'date'} color={'inherit'} style={{ width: '100%', textAlign: 'center' }}>
          {'Fecha de registro'}
        </Typography>
        <Button color={'inherit'} fullWidth >
          {!!lastValueObject.timestamp && moment(lastValueObject.timestamp).utcOffset('-0600').format('YYYY-MM-DD HH:mm')}
        </Button>
      </Box>
      <Typography key={'timezone'} variant='caption' color={'inherit'} style={{ width: '100%', textAlign: 'center', fontSize: 12 }}>
        {'*Hora Ciudad de México'}
      </Typography>
      <Typography key={'charts'} p={2} paddingLeft={3} fontWeight='700'>
        {'Gráficas:'}
      </Typography>
      <Box key={'chartbox'} marginBottom={2} style={{ borderRadius: 10, boxShadow: '0px 0px 5px gray', position: 'relative', overflow: 'hidden' }}>
        <ChartLive title={selected?.vc_nombre} registers={values} />
      </Box>
      <Typography key={'registers'} p={2} paddingLeft={3} fontWeight='700'>
        {'Registros:'}
      </Typography>
      <Box key={'registersitems'} marginBottom={2} style={{ borderRadius: 10, boxShadow: '0px 0px 5px gray', position: 'relative', overflow: 'hidden' }}>
        {[...values].reverse().map((val, index) => {
          return (
            <ListItem key={index} divider>
              <ListItemText>
                {!!val.timestamp && moment(val.timestamp).utcOffset('-0600').format('YYYY-MM-DD HH:mm')}
              </ListItemText>
              <ListItemText style={{ textAlign: 'right' }}>
                {val.nu_value}
                {` ${measureSelect?.medida?.vc_abreviatura || ''}`}
              </ListItemText>
            </ListItem>
          )
        })}
      </Box>
      <Typography key={'charts'} p={2} paddingLeft={3} fontWeight='700'>
        {'Usuarios:'}
      </Typography>
      <Box key={'chartbox'} marginBottom={2} style={{ borderRadius: 10, boxShadow: '0px 0px 5px gray', position: 'relative', overflow: 'hidden' }}>
        {
          <ListItem>
            <Typography>
              {'Sin usuarios asignados'}
            </Typography>
          </ListItem>
        }
      </Box>
      {/* <Box marginBottom={2} style={{ borderRadius: 10, boxShadow: '0px 0px 5px gray', position: 'relative', overflow: 'hidden' }}>
                <GaugeChart id="gauge-chart3"
                    nrOfLevels={30}
                    colors={['blue', 'green', 'green', 'orange', 'red']}
                    arcWidth={0.3}
                    percent={data?.percent || 0}
                    formatTextValue={(value) => {
                        return `${value} Ohms`
                    }}
                />
            </Box> */}

      {/* {
                dataDemo.filter(e => (e.dec * e.ratio) > 0).map((item, index) => {
                    const defaultValue = Math.random() * 100;
                    console.log('default', defaultValue);
                    return (
                        <Box marginBottom={2} key={index} style={{ borderRadius: 10, boxShadow: '0px 0px 5px gray', position: 'relative', height: '80vw', overflow: 'hidden' }}>
                            <Box style={{ position: 'relative' }}>
                                <Typography variant='h5' p={2} paddingLeft={3}>
                                    {item.name}
                                </Typography>
                                <Box p={3} key={index} style={{ position: 'relative' }}>
                                    <ViewerLevel radius={50} strokeWidth={20} defaultValue={100} invertedPanel={item.invertedPanel} />
                                    <MarkerAnimate title={item.unitMeasure} ratio={item.ratio} decimals={item.decimals} average={item.average} averageNegative={item.averageNegative} value={(item.dec * item.ratio)} />
                                </Box>
                            </Box>
                        </Box>
                    )
                })
            } */}
    </Box >
  );
}

export default ViewersLevels;