
import React, { useEffect, useRef, useState } from 'react'
import {
  useSelector
} from 'react-redux';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import { Avatar, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Zoom, useMediaQuery, useTheme } from '@mui/material';
import IconMask from '../../components/IconMask';

import './jitsi-custom.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import MeetButton from '../../components/forms/MeetButton';
import CatalogsIconButton from '../../components/customForms/CatalogsIconButton';
import { meets } from '../../constants/models';
import EditableModel from '../../components/EditableModel.js';
import app from '../../services';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { MeetingRoom } from '@mui/icons-material';
import moment from 'moment';

function VideoCallView({ startOnCall = false, selected, fixed = false, defaultRoomName = 'demo', onClose = () => { } }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const iframeRef = useRef();
  const ref = useRef();
  const institution = useSelector((state) => state.session.institution);
  const user = useSelector((state) => state.session.globalUser);
  const [displayName, setDisplayName] = useState(`${user?.vc_nombre || ' '} ${user?.vc_paterno || ' '} ${user?.vc_materno || ' '}`)
  const [modal, setModal] = useState(false);
  const [meet, setMeet] = useState(null);
  const [meetsHistory, setMeetsHistory] = useState([]);
  const [onCall, setOnCall] = useState(startOnCall)
  const [roomName, setRoomName] = useState(defaultRoomName)
  const history = useHistory();
  // console.log('________________________________________', { startOnCall, selected, fixed, defaultRoomName, onClose });
  useEffect(() => {
    setDisplayName(user?.vc_nombre);
  }, [user])
  useEffect(() => {
    console.log('ref', ref);
  }, [onCall])
  const getMeet = async (id) => {
    if (!!id) {
      const meetIncoming = await app.service('meets').get(id);
      setMeet(meetIncoming);
    }
  }
  const deleteMeet = async (id) => {
    if (!!id) {
      await app.service('meets').remove(id);
      getMeets();
    }
  }
  const getMeets = async (id) => {
    if (!!user) {
      const meetIncoming = await app.service('meets-users').find({
        query: {
          $limit: 500,
          $sort: { createdAt: -1 }, // Replace 'createdAt' with the field you want to sort by
          $and: [
            {
              id_usuario: user?.id
            }
            // ,
            // { id_institucion: institution?.id }
          ]
        }
      });
      const meetCreator = await app.service('meets').find({
        query: {
          $limit: 500,
          $sort: { createdAt: -1 }, // Replace 'createdAt' with the field you want to sort by
          $and: [
            {
              id_usuario: user?.id
            }
            // ,
            // { id_institucion: institution?.id }
          ]
        }
      });
      console.log('meetCreator', meetCreator)
      const dataStructured = [...(meetCreator.data || []).map(e => { return { id_usuario: user?.id, meet: e, id_meet: e.id } }), ...meetIncoming.data || []].sort((a, b) => moment(b.meet?.createdAt) - moment(a.meet?.createdAt));
      console.log('dataStructured', dataStructured)

      setMeetsHistory(dataStructured || []);
    }
  }
  console.log('user', user)

  const createMeet = async () => {
    console.log('create')
    const meetIncoming = await app.service('meets').create({
      id_usuario: user?.id,
      vc_descripcion: `Revision del activo: ${selected?.vc_nombre}`
    });
    setMeet(meetIncoming);
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location?.search);
    const roomNameEdit = searchParams.get('roomName'); // Obtiene el valor del parámetro 'id'
    console.log('roomname', roomNameEdit);
    getMeet(roomNameEdit)
  }, [history])
  useEffect(() => {
    if (!!selected)
      createMeet();
  }, [selected])
  useEffect(() => {
    if (!!user)
      getMeets();
  }, [])

  return (
    <Grid direction={'column'} container display={'flex'} style={{ height: '100%', width: '100%' }}>
      <Helmet >
        <title>Videoconferencias</title>
        <meta name="description" content="Videoconferencias" />
      </Helmet>
      <Grid item xs='auto' style={{ width: '100%', height: '100%', overflowY: 'scroll', position: 'relative' }}>
        {onCall ?

          <Box ref={iframeRef} style={{ position: fixed ? 'fixed' : 'relative', display: 'flex', width: '100%', height: '100%' }}>
            {console.log('renderizo meet')}
            < JitsiMeeting
              // domain="meet.thin-k.mx"
              domain="meet.tgone.app"
              ref={ref}
              // domain="meet.jit.si"
              roomName={meet?.id || roomName}
              configOverwrite={{
                startWithAudioMuted: true,
                disableModeratorIndicator: true,
                startScreenSharing: false,
                // enableEmailInStats: false,
                disableDeepLinking: true,
                // disableTileEnlargement: true
                analytics: {
                  disabled: true
                },
                // p2p: true,
                // resolution: 360, // Limitar la resolución a 360p
                toolbarButtons: [
                  'camera',
                  'chat',
                  'closedcaptions',
                  'desktop',
                  'download',
                  'embedmeeting',
                  'etherpad',
                  'feedback',
                  'filmstrip',
                  'fullscreen',
                  'hangup',
                  'help',
                  'highlight',
                  'invite',
                  'linktosalesforce',
                  'livestreaming',
                  'microphone',
                  'noisesuppression',
                  'participants-pane',
                  'profile',
                  'raisehand',
                  'recording',
                  'security',
                  'select-background',
                  'settings',
                  'shareaudio',
                  'sharedvideo',
                  'shortcuts',
                  'stats',
                  'tileview',
                  'toggle-camera',
                  'videoquality',
                  'whiteboard',
                ]
              }}
              interfaceConfigOverwrite={{
                MOBILE_APP_PROMO: false,
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,

                // OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron', 'safari'],
              }}
              userInfo={{
                displayName: displayName,

              }}
              onApiReady={(externalApi) => {
                // here you can attach custom event listeners to the Jitsi Meet External API
                // you can also store it locally to execute commands
                externalApi.on('readyToClose', () => {
                  console.log('saloooooooos')
                  setOnCall(false);
                  onClose();
                  // setHiddeVideoCall && setHiddeVideoCall()
                });
                externalApi.addListener('toolbarButtonClicked', (e) => {
                  console.log('toolbarButtonClicked------>', e)

                })
                externalApi.addListener('error', (e) => {
                  // // console.log('error------>', e)
                  // setOnCall(false)
                  // setOnCall(true)
                });



              }}
              getIFrameRef={(iframe) => {
                iframe.addEventListener('click', ((e) => {
                  console.log('e', e)
                }));
                iframe.style.width = '100%';
              }}

            />
            {/* <CatalogsIconButton
              model={meets}
              item={meet}
              component={
                <Button key={1} variant='contained' onClick={() => setOnCall(false)} size='small' style={{ position: 'absolute', top: 100, left: 8, boxShadow: '0px 0px 0px 5px gray'}}>
                  <IconMask color='white' url={'/assets/icons/edit.svg'} size={25} />
                </Button>
              }
              mode='component'
            /> */}

          </Box>
          :
          <Box width={'100%'}>
            <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'start'}>
              <Typography style={{ width: '100%', fontWeight: 700, fontSize: '1.2rem', margin: 16 }}>
                {'Reuniones:'}
              </Typography>
              <IconButton onClick={() => setModal(true)} style={{ margin: 6 }}>
                <IconMask color={'lightGray'} url={'assets/icons/add.svg'} size={30} />
              </IconButton>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell key={'rol'}>Rol</TableCell>
                    <TableCell key={'clave'}>Clave</TableCell>
                    <TableCell key={'description'} align="left">Descripción</TableCell>
                    <TableCell key={'date'} align="left">Fecha y Hora</TableCell>
                    <TableCell key={'meet'} align="left">Meet</TableCell>
                    <TableCell key={'delete'}>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meetsHistory.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell key={'rol'}>{row.id_usuario === user?.id ? 'Titular' : 'Invitado'}</TableCell>
                      <TableCell key={'id'} component="th" scope="row">
                        {row.id_meet}
                      </TableCell>
                      <TableCell key={'desc'} align="left">{row.meet?.vc_descripcion || 'Sin descripción'}</TableCell>
                      <TableCell key={'date'} align="left">{moment(row.meet?.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                      <TableCell key={'go'} >
                        <IconButton onClick={() => setMeet(row.meet)}>
                          <IconMask color={'white'} url={'assets/icons/meet.svg'} size={25} />
                        </IconButton>
                      </TableCell>
                      <TableCell key={'delete'}>
                        <IconButton disabled={user?.id !== row.id_usuario} onClick={() => deleteMeet(row.id_meet)}>
                          <IconMask color={user?.id !== row.id_usuario ? 'gray' : 'white'} url={'assets/icons/delete.svg'} size={20} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {
              !meetsHistory.length &&
              <Button fullWidth style={{ marginTop: 24 }}>
                {'¡No tienes ninguna reunión activa!'}
              </Button>
            }
          </Box>
        }
      </Grid>
      <Dialog
        open={(modal || !!meet) && !onCall}
        TransitionComponent={Zoom}
        fullScreen={!isMd}
      >
        <Box p={2} width={'100%'} style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <Box p={0} style={{ boxShadow: '0px 0px 5px rgba(200,200,200,0.5)', borderRadius: 8, backgroundColor: 'transparent', height: '100%', width: 600, maxWidth: '100%', minWidth: 300, alignSelf: 'center' }}>
            <Box paddingTop={1} width={'100%'} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src="assets/images/logo192.png" style={{ backgroundColor: 'transparent', height: 30, padding: 2 }} />
              {/* <Avatar variant='square' src="assets/images/cfe.png" style={{ backgroundColor: 'transparent', height: 100, width: 100, padding: 8, alignSelf: 'center' }} /> */}
            </Box>
            <DialogTitle style={{ alignSelf: 'center', width: '100%', textAlign: 'center' }}>
              {'Sala de soporte'}
            </DialogTitle>
            <Grid container display={'flex'} style={{}}>
              {/* 
                  <TextField fullWidth label='Cliente' type='text' placeholder='Selecciona...' defaultValue={displayName} style={{ marginBottom: 20 }} />
                  <TextField fullWidth label='Producto' type='text' placeholder='Capture...' defaultValue={''} style={{ marginBottom: 20 }} />
                  <TextField fullWidth multiline minRows={3} label='Descripción' type='text' placeholder='Describe...' defaultValue={''} style={{ marginBottom: 20 }} />
                  <MeetButton onClickStart={(roomNameEdit) => {
                    setRoomName(roomNameEdit);
                    setOnCall(true);
                  }} defaultValue={null} /> 
                  */}
              {
                !!user || !!meet ?
                  <EditableModel
                    key={'video-call'}
                    withOutTitle
                    model={meets}
                    item={meet}
                    closeButton={
                      <Button variant='outlined' fullWidth onClick={() => {
                        console.log('onclose', onCall, modal, meet);
                        if (!!meet || (!!modal && !onCall)) {
                          setMeet(null);
                          setModal(false);
                        };
                        getMeets();
                      }}>
                        {'Salir'}
                      </Button>
                    }
                    onClose={() => {
                      console.log('onclose')
                    }}
                    onSave={(e) => setMeet(e)}
                    endButtons={
                      !!meet &&
                      <Button
                        key={'startMeet'}
                        fullWidth
                        variant='contained'
                        onClick={(e) => setOnCall(true)}
                      >
                        <IconMask color={'white'} url={'assets/icons/meet.svg'} size={25} />
                      </Button>
                    }
                  /> :
                  <Box p={2} width={'100%'}>
                    <Typography style={{ width: '100%', textAlign: 'center' }}>
                      {'¡Para crear una reunión debes iniciar sesión!'}
                    </Typography>
                    <Button
                      fullWidth
                      onClick={() => history.push('/')}
                    >
                      {'Inicia sesión'}
                    </Button>
                  </Box>
              }

            </Grid>
          </Box>
        </Box>
      </Dialog>
    </Grid >
  )
}

export default VideoCallView


