import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react"



export default function GaugeLinear({
    title,
    value = 0,
    height = 50,
    parts = 50,
    min = 0,
    max = 100,
    ranges = [
        { color: 'green', min: 0, max: 30 },
        { color: 'yellow', min: 30, max: 60 },
        { color: 'orange', min: 60, max: 80 },
        { color: 'red', min: 80, max: 100 },
    ]
}) {

    console.log('max------->', max)
    const [state, setstate] = useState({})
    useEffect(() => {
        const components = [];
        const totalRange = max - min;


        const rest = value - min;

        let linearGradient = '';
        ranges.filter(e => e.min < max).map((range, index) => {
            const rangeInn = range.max - range.min;
            const percent = (rangeInn / totalRange) * 100;
            linearGradient = linearGradient + ', ' + range.color;
        })
        linearGradient = ('linear-gradient(' + linearGradient + ')').replace('linear-gradient(, ', 'linear-gradient( to right, ');
        setstate({ components, linearGradient, left: (rest / totalRange) * 100 });
        // console.log('totalRange', totalRange);
        // console.log('max', max);
        // console.log('min', min);
        // console.log('value', value);
        // console.log(' (rest / totalRange) * 100', (rest / totalRange) * 100);
    }, [value]);
    return (
        <div
            key='marker'
            style={{ width: '100%', height: height, padding: '3px 0px', position: 'relative' }}>
            <div style={{ width: '100%', height: '100%', backgroundImage: state.linearGradient, borderRadius: 10, boxShadow: '0px 0px 4px gray' }}>
                <div style={{ position: 'absolute', height: '100%', width: 4, borderRadius: 2, boxShadow: '0px 0px 5px black', top: 0, left: `${state.left >= 100 ? 99 : state.left}%`, backgroundColor: 'black' }} />
            </div>
        </div>
    )
}