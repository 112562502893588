import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "../../common/DatePicker";
import { TextField, Button, FormGroup, IconButton, Dialog, FormControl, Divider, DialogContentText, Typography, Stack, Grid, useMediaQuery, useTheme } from "@mui/material";
import SearchSelect from "./SearchSelect";
import { CropFreeOutlined, PlaylistAdd, PlaylistAddOutlined, QrCode2Outlined } from "@mui/icons-material";
import models from "../../constants/models";
import { Box } from "@mui/system";
import TableCustom from "../TableCustom";
import { inputLabelClasses } from "@mui/material/InputLabel";
import SearchSelectMultiple from "./SearchSelectMultiple";
import TimePicker from "../../common/TimePicker";
import PasswordInput from "./PasswordInput";
import DateTimeRange from "./DateTimeRange";
import FieldContainerIcon from "./FieldContainerIcon";
import MeetButton from "./MeetButton";
import CURPInput from "./customs/CURPInput";

export default function Forms({ model, item = {}, variant = 'standard', path = '' }) {
    const { register, handleSubmit } = useForm();
    const [control, setcontrol] = useState(false)
    const [scan, setScan] = useState(null);
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('xs'));
    const getFieldIcon = (field) => {
        switch (true) {
            case (!!field.icon === 'none'): {
                return field.icon;
            }
            case (!!field.icon): {
                return field.icon;
            }
            case (field.numeric): {
                return 'dial-pad.svg';
            }
            case (field.component === 'dateTimeRange'): {
                return 'calendario.svg';
            }
            case (field.component === 'password'): {
                return 'proteccion.svg';
            }
            case (field.component === 'email'): {
                return 'correo-electronico.svg';
            }
            case (field.component === 'select'): {
                return 'search.svg';
            }
            case (`${field.id}`.startsWith('vc_') && field?.multiple): {
                return 'review.svg';
            }
            case (`${field.id}`.startsWith('vc_')): {
                return 'edit.svg';
            }
            case (`${field.id}`.startsWith('tm_')): {
                return 'tiempo.svg';
            }
            case (`${field.id}`.startsWith('dt_')): {
                return 'calendario.svg';
            }
            default: {
                return undefined;
            }
        }
    }
    const [newRows, setNewRows] = useState([]);
    const renderFields = (field, index) => {
        let Component;
        switch (true) {
            case (`${field.id}`.startsWith('dt_')): {
                Component = <DatePicker
                    id={(model.id || '') + (path || '') + field.id}
                    fullWidth
                    key={index}
                    variant={variant}
                    placeholder={field.placeholder}
                    label={`${field.label}${field.required ? '*' : ''}`}
                    defaultValue={item ? item[field.id] : ''}

                />
                break;
            }
            case (`${field.id}`.startsWith('tm_')): {
                console.log('timeeee', item ? item[field.id] : '')
                Component = <TimePicker
                    id={(model.id || '') + (path || '') + field.id}
                    fullWidth
                    key={index}
                    variant={variant}
                    placeholder={field.placeholder}
                    label={`${field.label}${field.required ? '*' : ''}`}
                    defaultValue={item ? item[field.id] : ''}

                />
                break;
            }
            case (field.component === 'select'): {
                Component = <SearchSelect
                    variant={variant}
                    fullWidth
                    id={(model.id || '') + (path || '') + field.id}
                    field={field}
                    modelBase={model}
                    key={index}
                    placeholder={field.placeholder}
                    label={`${field.label}${field.required ? '*' : ''}`}
                    defaultValue={item ? item[field.id] : null}
                />
                break;
            }
            case (field.hidden): {
                Component = <input
                    id={(model.id || '') + (path || '') + field.id}
                    value={item ? item[field.id] : ''} hidden
                    readOnly
                />
                break;
            }
            case (field.component === 'dateTimeRange'): {
                Component = <DateTimeRange
                    field={field}
                    item={item}
                    path={(model.id || '') + (path || '')}
                />
                break;
            }
            case (field.component === 'meet-button'): {
                Component = <MeetButton
                    field={field}
                    id={(model.id || '') + (path || '') + field.id}
                    item={item}
                    value={item ? item[field.id] : ''}
                    path={(model.id || '') + (path || '')}
                />
                break;
            }

            case (field.component === 'curp'): {
                Component = <CURPInput
                    field={field}
                    id={(model.id || '') + (path || '') + field.id}
                    item={item}
                    value={item ? item[field.id] : ''}
                    path={(model.id || '') + (path || '')}
                />
                break;
            }
            case (`${field.id}`.toLowerCase().includes('password')): {
                Component = <PasswordInput
                    fullWidth
                    id={(model.id || '') + (path || '') + field.id}
                    key={index}
                    field={field}
                    label={`${field.label}${field.required ? '*' : ''}`}
                    defaultValue={item ? item[field.id] : ''}
                    disabled={!!field.readOnly}

                />
                break;

            }
            default: {
                // console.log('field', field)
                Component = <TextField
                    variant={variant}
                    fullWidth
                    {...field.hidden ? { style: { width: 0, height: 0 } } : {}}
                    id={(model.id || '') + (path || '') + field.id}
                    key={index}
                    multiline
                    placeholder={field.label}
                    label={`${field.label}${field.required ? '*' : ''}`}
                    defaultValue={item ? item[field.id] : ''}
                    disabled={!!field.readOnly}
                    InputLabelProps={{
                        sx: {
                            // set the color of the label when not shrinked
                            color: "gray",
                            padding: '5px 10px',
                            [`&.${inputLabelClasses.shrink}`]: {
                                // set the color of the label when shrinked (usually when the TextField is focused)
                                padding: '0px 14px',
                            }
                        }
                    }}
                    InputProps={{ disableUnderline: true, placeholder: field.label, style: { padding: '8px 10px', backgroundColor: 'rgba(200,200,200,0.1)', boxShadow: '0px 0px 2px gray', borderRadius: 10 } }}

                    inputProps={{ inputMode: field.numeric ? 'numeric' : 'text', pattern: '[0-9]*' }
                    }
                />
            }
        }
        return Component
    }
    const visibles = model?.columns?.filter(e => (!e?.readOnly || !!{ item }[e?.id]) && (e.relation?.id_type === 'toOne' || !e.relation) && !e.hidden) || [];
    const noVisibles = model?.columns?.filter(e => e.hidden) || [];
    return (
        <FormGroup
            onKeyDown={(e) => {
                // console.log(e)
            }}
            onKeyUp={(e) => {
                // console.log('up', e)
            }}
            onClick={(e) => console.log('hizo clic ' + model.id)}
            style={{
                marginTop: 0,
                boxShadow: !!path ? '0px 0px 3px rgba(50,50,50,0.5)' : 'none',
                backgroundColor: !!path ? 'rgba(200,200,200,0.1)' : 'none',
                borderRadius: !!path ? 10 : 'none', padding: 8, paddingBottom: 0,
                minWidth: '30vw',
                maxWidth: '100%'
            }}>
            <Grid
                container
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent='flex-start'
            >
                {
                    !!item &&
                    visibles.map((field, index) => {
                        let xs = true
                        xs = 4
                        switch (field.width) {
                            case ('fullWidth'): {
                                xs = 12;
                                break;
                            }
                            case ('minimal'): {
                                xs = 1;
                                break;
                            }
                            case (undefined): {
                                xs = 12 || field.width;
                                break;
                            }
                            // eslint-disable-next-line no-lone-blocks
                            default: {
                                xs = 12 || field.width;
                                break;
                            };
                        }
                        if (index === visibles.length - 1) {
                            xs = true
                        }
                        return (
                            <Grid item xs={xs} key={index} paddingBottom={1}>
                                <FieldContainerIcon field={field} index={index} icon={getFieldIcon(field)}>
                                    {renderFields(field, index)}
                                </FieldContainerIcon>
                            </Grid>
                        );
                    })
                }

            </Grid>
            {
                !!item &&
                noVisibles.map((field, index) => {
                    return <input key={'hidden' + index} readOnly id={field.id} value={item ? item[field.id] : ''} hidden />
                })
            }
            {
                !!item &&
                model?.columns?.filter(e => (e.relation?.id_type === 'toMany')).map((field, index) => {
                    const rows = {
                        saved: item[field.id] || [],
                        news: newRows
                    }
                    if (!!item[field?.relation?.keyItem])
                        return (
                            <Box key={index} paddingLeft={0} paddingRight={0}>
                                <SearchSelectMultiple item={item} childrens={item[field.id]} field={field} path={(path || '') + field.id + '.'} />
                            </Box>
                        )
                    // return (
                    //     <Box key={index} height={500}>
                    //         <div style={{ marginTop: 8, marginBottom: 8 }} />
                    //         <TableCustom  models={[modelInner]} mode={field.mode || 'auto'} path={(path || '') + field.id + '.'} />
                    //     </Box>
                    // )

                })
            }
            <Dialog
                open={!!scan}
                onClose={() => setScan(null)}
            >
                {/* <QRReader
                    onClose={() => setScan(null)}
                    onLoad={(e) => {
                        if (e) {
                            console.log('eeeee', e);
                        }
                    }}
                /> */}
            </Dialog>
        </FormGroup>
    );
}
