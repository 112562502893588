
import libs from '../constants/catalogos'

export const initState = {
      ...libs
};
const libsReducers = (state = initState, action) => {
      switch (action.type) {
            //Change character name
            default:
                  return state
      }
}

export default libsReducers;