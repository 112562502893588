import moment from "moment";

const months = [
    { vc_nombre: 'enero', value: '01' },
    { vc_nombre: 'febrero', value: '02' },
    { vc_nombre: 'marzo', value: '03' },
    { vc_nombre: 'abril', value: '04' },
    { vc_nombre: 'mayo', value: '05' },
    { vc_nombre: 'junio', value: '06' },
    { vc_nombre: 'julio', value: '07' },
    { vc_nombre: 'agosto', value: '08' },
    { vc_nombre: 'septiembre', value: '09' },
    { vc_nombre: 'octubre', value: '10' },
    { vc_nombre: 'noviembre', value: '11' },
    { vc_nombre: 'diciembre', value: '12' },
]

const commands = [
    { vc_nombre: 'reporte total', raw: ' generar el reporte total', value: '001' },
    { vc_nombre: 'reporte del area', raw: ' generar el reporte del area', value: '001' },
    { vc_nombre: 'el ultimo registro', raw: 'Mostrar el último registro', value: '002' },
    { vc_nombre: 'el promedio', raw: 'Mostrar el promedio', value: '003' },
    { vc_nombre: 'valor mas alto', raw: 'Mostrar el registro mas alto', value: '004' },
    { vc_nombre: 'valor mas bajo', raw: 'Mostrar el registro mas bajo', value: '005' },
    { vc_nombre: 'responsables de area', raw: 'Mostrar a los responsables del área', value: '006' },
    { vc_nombre: 'responsables del dispositivo', raw: 'Mostrar a los responsables del dispositívo', value: '007' }
]

const functions = {
    getDates: (str) => {
        let date = { day: null, month: null, year: moment().get('year') }
        let attributes = [];
        const chuncks = `${str}`.split(' ');
        switch (true) {
            case (`${str}`.includes('ultimo mes')): {
                const since = moment().add(-1, 'month')
                const to = moment()
                attributes.push({
                    type: 'date',
                    order: attributes.filter(e => e.type === 'date').length,
                    value: since.format('YYYY-MM-DD'),
                    lapse: 0
                })
                attributes.push({
                    type: 'date',
                    order: attributes.filter(e => e.type === 'date').length,
                    value: to.format('YYYY-MM-DD'),
                    lapse: (since).diff(to, 'days')
                })
                break;
            }
            default: {
                chuncks.map((item, index) => {
                    const number = item * 1 !== NaN;
                    switch (true) {
                        case (!!months.find(e => e.vc_nombre === item)): {
                            date.month = months.find(e => e.vc_nombre === item).value
                            if (date.day) {
                                const lastDate = ([...attributes.filter(e => e.type === 'date')].pop())
                                const obj = {
                                    type: 'date',
                                    order: attributes.filter(e => e.type === 'date').length,
                                    value: `${date.year}-${date.month}-${date.day}`,
                                    lapse: lastDate ? (moment(`${date.year}-${date.month}-${date.day}`).diff(lastDate?.value, 'days')) : 0
                                }
                                const next = chuncks[index + 1];
                                if (!(!!next && ['de', 'del'].includes(chuncks[index + 1]))) {
                                    attributes.push(obj);
                                    date = { day: null, month: null, year: moment().get('year') };
                                }
                            }
                            break;
                        }
                        case (number && (item * 1 > 2020 && item * 1 <= moment().get('year'))): {
                            const lastDate = ([...attributes.filter(e => e.type === 'date')].pop())
                            date.year = `${item}`
                            if (date.day) {
                                attributes.push({
                                    type: 'date',
                                    order: attributes.filter(e => e.type === 'date').length,
                                    value: `${date.year}-${date.month}-${date.day}`,
                                    lapse: lastDate ? (moment(`${date.year}-${date.month}-${date.day}`).diff(lastDate?.value, 'days')) : 0
                                })
                                date = { day: null, month: null, year: moment().get('year') };
                            }
                            break;
                        }
                        case (number && (item * 1 <= 31) && (item * 1 > 0)): {
                            date.day = item * 1 < 10 ? `0${item}` : item;
                            break;
                        }
                        default: break;
                    }
                })
            }
        }

        return attributes;
    },
    getWords: (str) => {
        return `${str}`.split(' ');
    },
    getCommands: (str) => {
        return commands.filter(e => str.includes(e.vc_nombre));

    },
    getZones: (str, zones) => {
        return zones.filter(e => str.includes(e.vc_nombre));

    },
    getDevices: (str, devices) => {
        return devices.filter(e => str.includes(e.vc_nombre));
    },
    getReport: (type, data) => {

    }
};

export default function IALayer(searchText, zones = [], devices = [], cb) {
    const str = `${searchText}`.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
    
    const data = {
        dates: functions.getDates(str).filter(e => e.type === 'date'),
        words: functions.getWords(str),
        commands: functions.getCommands(str),
        devices: functions.getDevices(str, devices),
        zones: functions.getZones(str, zones)
    }
    // console.log('data', data);
    return data;
} 