
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import "firebase/compat/database";
import env from '@beam-australia/react-env'

const firebaseConfig = {
    apiKey: env("FIREBASE_API_KEY"),
    authDomain: env("FIREBASE_AUTH_DOMAIN"),
    projectId: env("FIREBASE_PROJECT_ID"),
    storageBucket: env("FIREBASE_STORAGE_BUCKET"),
    messagingSenderId: env("FIREBASE_MESSAGING_SENDER_ID"),
    appId: env("FIREBASE_APP_ID"),
    measurementId: env("FIREBASE_MEASUREMENT_ID")
};
firebase.initializeApp(firebaseConfig);
export default firebase;
