import React from "react";

import _ from "lodash";

import { Popover, Button, Box, Divider } from "@mui/material";
import DateRangePicker from "./DateRangePicker";
import moment from "moment";
import { CalendarViewDay, DateRangeOutlined } from "@mui/icons-material";

export default function ButtonDateRange({
  onChange = () => { },
  value = [],
  defaultValue = [null, null],
  closeOnChange = false,
  showAcceptButton = false,
  fullWidth = false,
  limits = [null, null]
}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [range, setRange] = React.useState(defaultValue || [null, null]);
  const [disabled, setDisabled] = React.useState(false);

  const inicio = range[0] ? moment(range[0]).format("DD/MM/YY") : "";
  const fin = range[1] ? moment(range[1]).format("DD/MM/YY") : "";
  // console.log('inicio', inicio, fin);
  React.useEffect(() => {
    setDisabled(range.includes(null));

    if (!range.includes(null)) {
      if (!showAcceptButton && closeOnChange) {
        onChange(range);
        setAnchorEl(null);
      }
      if (!showAcceptButton && !closeOnChange) {
        onChange(range);
      }
    }
  }, [range]);
  console.log('anchorEll', anchorEl);
  return ([
    <Button
      startIcon={<DateRangeOutlined />}
      fullWidth={fullWidth}
      onClick={(e) => {
        setAnchorEl(e.currentTarget);
      }}
    >
      {inicio && fin ? `${inicio} - ${fin}` : "(DD/MM/YY) - (DD/MM/YY)"}

    </Button>,
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <DateRangePicker
        limits={limits}
        value={range}
        onChange={(res) => {
          console.log('res change', moment(res[0]), moment(res[1]))
          setRange(res);
        }}
        onClose={() => setAnchorEl(null)}
      />
      {showAcceptButton && [
        <Divider />,
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button
            disabled={disabled}
            color="primary"
            variant="contained"
            onClick={() => {
              setAnchorEl(null);
              onChange(range);
            }}
          >
            Aceptar
          </Button>
        </Box>,
      ]}
    </Popover>
  ]
  );
};
