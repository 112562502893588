import React from 'react'
import { GoogleMap, Marker, useJsApiLoader, } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 24.682935564229783,
  lng: -102.93904243539743
};

function Maps({ points, setSelected = () => { } }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAYjneN0-jgdAHM5TDksjVuEMP5MG1vmZE",

  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={4}
      onLoad={onLoad}
      onUnmount={onUnmount}

    >
      {
        points.map((point, index) => {
          var latlng = new window.google.maps.LatLng(parseFloat(point.vc_lat), parseFloat(point.vc_lon));
          const size = new window.google.maps.Size(40, 40);
          return (
            <Marker onClick={() => setSelected(point)} key={index} position={latlng} icon={{
              url: 'assets/icons/grounding.svg',
              scaledSize: size,
              size: size,
              fillColor: "white",
              fillOpacity: 1,
            }} />
          )
        })
      }
      { /* Child components, such as markers, info windows, etc. */}
    </GoogleMap>
  ) : <></>
}

export default React.memo(Maps)