import moment from "moment";

var config = {
    series: [{
        data: [
           
        ]
    }],
    options: {
        chart: {
            id: 'area-datetime',
            type: 'line',
            height: 350,
            zoom: {
                autoScaleYaxis: true
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                color: '#000',
                opacity: 0.35
            }
        },
        annotations: {
            yaxis: [{
                y: 30,
                borderColor: 'red',
                label: {
                    show: true,
                    text: 'Support',
                    style: {
                        color: "#fff",
                        background: '#00E396'
                    }
                }
            }],
            xaxis: [{
                x: new Date('14 Nov 2012').getTime(),
                borderColor: '#999',
                yAxisIndex: 0,
                label: {
                    show: true,
                    text: 'Rally',
                    style: {
                        color: "#fff",
                        background: '#775DD0'
                    }
                }
            }]
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
            style: 'hollow',
        },
        xaxis: {
            type: 'datetime',
            min: new Date('01 Mar 2012').getTime(),
            tickAmount: 6,
        },
        tooltip: {
            x: {
                formatter: (e) => {
                    console.log('e', e);
                    return moment(e).utcOffset('-0600').format('DD/MM/YYYY HH:mm')
                }
            }
        },
        stroke: {
            curve: 'smooth',
            color: 'red',

        },
        fill: {
            // type: 'gradient',
            // gradient: {
            //     shadeIntensity: 1,
            //     opacityFrom: 0.7,
            //     opacityTo: 0.9,
            //     stops: [0, 100]
            // }
        },

    }
};

export default config;