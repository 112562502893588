/* eslint-disable use-isnan */
import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import ReactECharts from 'echarts-for-react';  // or var ReactECharts = require('echarts-for-react');
import * as echarts from 'echarts';

import useMeasure from 'react-use-measure';
import { useTheme } from '@mui/styles';

const colorsArray = ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'];
function LineEchart({ series, title = '', animationDuration = 10000, gradient = true }) {
    const [loading, setLoading] = useState(false);
    const [refChart, boundInner] = useMeasure()
    const theme = useTheme()
    const [customConfig, setCustomConfig] = useState({});
    useEffect(() => {
        setCustomConfig({});
        const categories = (series[0] || {}).data?.map(e => e[0]);
        setCustomConfig({ ...customConfig, options: { xaxis: { type: 'datetime', categories: categories } }, series })
    }, [series])
    console.log('suc', customConfig);
    return (
        <Paper ref={refChart} style={{ display: 'flex', borderRadius: 10, height: '100%', width: '100%', overflow: 'hidden' }}>
            {
                !loading &&
                <ReactECharts
                    style={{ width: boundInner.width, height: boundInner.height }}
                    showLoading={loading && {
                        type: 'default',
                        opts: {
                            text: '',
                            maskColor: 'rgba(0, 0, 0, 0.8)',
                            zlevel: 0,

                            // Font size. Available since `v4.8.0`.
                            fontSize: 12,
                            // Show an animated "spinner" or not. Available since `v4.8.0`.
                            showSpinner: true,
                            // Radius of the "spinner". Available since `v4.8.0`.
                            spinnerRadius: 10,
                            // Line width of the "spinner". Available since `v4.8.0`.
                            lineWidth: 5,
                            // Font thick weight. Available since `v5.0.1`.
                            fontWeight: 'normal',
                            // Font style. Available since `v5.0.1`.
                            fontStyle: 'normal',
                            // Font family. Available since `v5.0.1`.
                            fontFamily: 'sans-serif'
                        }
                    }}
                    option={{

                        animationDuration: animationDuration || 10000,
                        color: customConfig.series?.length > 1 ? customConfig.series?.map(e => e.color) : [theme.palette.primary[600],...colorsArray],
                        backgroundColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                opacity: 0,
                                color: theme.palette.primary[50]
                            },
                            {
                                offset: 1,
                                color: theme.palette.primary[100],
                                opacity: 0.3
                            }
                        ]),
                        title: {
                            show: false,
                            text: title
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    // backgroundColor: ,
                                }
                            },
                            valueFormatter: (value) => value.toFixed(2)
                        },
                        legend: {
                            data: customConfig.series?.map(e => e.name),
                        },
                        toolbox: {
                            // feature: {
                            //     saveAsImage: {}
                            // }
                        },
                        grid: {
                            left: '0%',
                            right: '0%',
                            bottom: '0%',
                            containLabel: false
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: false,
                                data: customConfig.options?.xaxis?.categories || []
                            }
                        ],
                        yAxis: [
                            {
                                interval: ((((customConfig.series || [])[0] || {}).max - ((customConfig.series || [])[0] || {}).min) * 0.2).toFixed(0),
                                show: false,
                                type: 'value'
                            }
                        ],
                        series: customConfig.series?.map((serie, index) => {
                            // console.log('serie', serie);
                            return ({
                                name: serie.name,
                                endLabel: {
                                    show: false,
                                    formatter: function (params) {
                                        return params.seriesName + ': ' + (params.value).toFixed(2);
                                    }
                                },
                                type: 'line',
                                // stack: 'Total',
                                markPoint: {
                                    
                                    data: [
                                        { type: 'max', name: 'Max', symbol: 'pin', label: {fontSize: 10},},
                                        { type: 'min', name: 'Min', symbol: 'pin', label: {fontSize: 10},},
                                    ]
                                  },
                                markLine: {
                                    data: [
                                        {
                                            type: 'min',
                                            name: 'Min',
                                            label: {
                                                color: 'gray',
                                                textBorderColor: 'transparent',
                                                position: 'insideStartTop'
                                            } 
                                        }
                                    ]
                                },
                                smooth: true,
                                lineStyle: {
                                    width: !gradient ? 1 : 0.1,
                                    opacity: 1,
                                    shadowColor: 'black',
                                    shadowOffsetX: 0,
                                    shadowBlur: 0,
                                },
                                showSymbol: false,
                                areaStyle: {
                                    show: customConfig.series?.length > 1 ? false : true,
                                    opacity: gradient ? 0.8 : 0,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: theme.palette.primary[100],
                                            opacity: 0.3
                                        },
                                        {
                                            offset: 1,
                                            opacity: 0,
                                            color: theme.palette.primary[200]
                                        }
                                    ])
                                },
                                emphasis: {
                                    focus: 'series'
                                },
                                data: serie.data?.map(e => e[1] || 0) || []
                            })
                        })
                    }}
                    notMerge={true}
                    lazyUpdate={false}
                    theme={'dark'}
                    opts={{}}
                />
            }
        </Paper>
    );
}

export default LineEchart;