import React, { Suspense, lazy, useEffect } from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import HeaderAppBar from './components/HeaderAppBar';
import Layout from './Layout';
import store from './redux/store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blueGrey, green, grey, red } from '@mui/material/colors';
import MiniDrawer from './components/MiniDrawer';
import Loading from './common/Loading';
import DashboardView from './views/DashboardView';
import { messaging } from './services/firebase';
import firebase from './services/firebase';
import app from './services';

const LoginView = lazy(() => import('./views/LoginView'));
const OrdersView = lazy(() => import('./views/OrdersView'));
const RegisterView = lazy(() => import('./views/RegisterView'));
const NoMatch = lazy(() => import('./views/NoMatch'));

const VideoCallView = lazy(() => import('./views/VideoCallView'));
const ConfigView = lazy(() => import('./views/ConfigView'));
const Directory = lazy(() => import('./views/Directory'));
const RegistersView = lazy(() => import('./views/RegistersView'));

const DevicesView = lazy(() => import('./views/DevicesView'));
const HumanResourses = lazy(() => import('./views/HumanResourses'));
const Companies = lazy(() => import('./views/Companies'));
const LinksDevices = lazy(() => import('./views/LinksDevices'));
const Locations = lazy(() => import('./views/Locations'));
const IndustrialAssets = lazy(() => import('./views/IndustrialAssets'));
const Devices = lazy(() => import('./views/Devices'));
const Sections = lazy(() => import('./views/Sections'));
const Catalogs = lazy(() => import('./views/Catalogs'));
const WorkGroups = lazy(() => import('./views/WorkGroups'));
const Calendar = lazy(() => import('./views/Calendar'));
const ViewerView = lazy(() => import('./views/ViewerView'));
const HomeView = lazy(() => import('./views/HomeView'));


const color = blueGrey
let theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        borderRadius: '10px',
      },
      rounded: {
        borderRadius: '10px',
      }
    },
    MuiCardHeader: {
      root: {
        position: 'relative',
      }
    },

    MuiFormControl: {
      marginNormal: {
        marginTop: '4px',
        marginBottom: '8px'
      },
      root: {
        marginTop: '4px',
        marginBottom: '8px'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '8px 14px'
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 10px) scale(1)'
      }
    },
    MuiDrawer: {
      paper: {
        // backgroundColor: grey
      }
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        bottom: '-4px',
        left: '-14px',
        color: '#f3564b',
        width: '100%'
      },
      contained: {
        margin: '0px 14px 0'
      }
    },
    MuiInputBase: {
      root: {
        marginBottom: '10px'
      }
    },

  },
  palette: {
    semitransparent: `rgba(${color[500]},0.5)`,
    primary: color,
    secondary: {
      main: '#fafafa'
    },
    type: 'light',
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Nunito',
  },

  MuiExpansionPanelSummary: {

    root: {
      "&$expanded": {
        boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)"
      }
    }
  }
});

function App() {
  let persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Layout>
          <MiniDrawer>
            <Switch>
              <Route key={'/'} exact={true} path="/">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  {/* <DashboardView /> */}
                  <OrdersView/>
                </Suspense>
              </Route>
              <Route key={'/home'} path="/home">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <HomeView />
                </Suspense>
              </Route>
              <Route key={'/work-groups'} path="/work-groups">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <WorkGroups />
                </Suspense>
              </Route>
              <Route key={'/calendar'} path="/calendar">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <Calendar />
                </Suspense>
              </Route>
              <Route key={'/users'} path="/users">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <HumanResourses />
                </Suspense>
              </Route>
              <Route key={'/companies'} path="/companies">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <Companies />
                </Suspense>
              </Route>
              <Route key={'/links'} path="/links">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <LinksDevices />
                </Suspense>
              </Route>
              <Route key={'/locations'} path="/locations">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <Locations />
                </Suspense>
              </Route>
              <Route key={'/sections'} path="/sections">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <Sections />
                </Suspense>
              </Route>
              <Route key={'/industrial-assets'} path="/industrial-assets">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <IndustrialAssets />
                </Suspense>
              </Route>
              <Route key={'/devices'} path="/devices">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <Devices />
                </Suspense>
              </Route>
              <Route key={'/login'} path="/login">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <LoginView />
                </Suspense>
              </Route>
              <Route key={'/video-call'} path="/video-call">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <VideoCallView />
                </Suspense>
              </Route>
              <Route key={'/viewer'} path="/viewer">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <ViewerView />
                </Suspense>
              </Route>
              {/* <Route path="/directory">
                                                <Directory />
                                          </Route> */}
              <Route key={'/config'} path="/config">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <ConfigView />
                </Suspense>
              </Route>
              <Route key={'/catalogs'} path="/catalogs">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <Catalogs />
                </Suspense>
              </Route>
              <Route key={'/registers'} path="/registers">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <RegistersView />
                </Suspense>
              </Route>

              <Route key={'*'} path="*">
                <Suspense fallback={<div style={{}}><Loading /></div>}>
                  <NoMatch />
                </Suspense>
              </Route>
            </Switch>
          </MiniDrawer>
        </Layout>
      </PersistGate>
    </Provider>
  );
}

export default App;