import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import DatePickerMUI from '@mui/lab/DatePicker';
import { inputLabelClasses } from '@mui/material';

function DatePicker({ ...props }) {
  const [value, setValue] = React.useState(props.defaultValue ? new Date(props.defaultValue) : null);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerMUI
        closeOnSelect
        views={['day', 'month', 'year']}
        {...props}
        onChange={handleChange}
        value={value}
        label={props.label}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => <TextField id={props.id} fullWidth variant='standard' {...params}
          InputLabelProps={{
            sx: {
              // set the color of the label when not shrinked
              color: "gray",
              padding: '5px 10px',
              [`&.${inputLabelClasses.shrink}`]: {
                // set the color of the label when shrinked (usually when the TextField is focused)
                padding: '0px 14px',
              }
            }
          }}
          InputProps={{ ...params.InputProps, disableUnderline: true, placeholder: props.label, style: { padding: '6px 10px', backgroundColor: 'rgba(200,200,200,0.1)', boxShadow: '0px 0px 2px gray', borderRadius: 10 } }}
        />}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
