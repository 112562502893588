import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Fade, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, MenuList, Paper, Popper, TextField, Typography, inputLabelClasses } from "@mui/material";
import { useTheme } from "@mui/styles";
import moment from "moment";
import React, { useState } from "react";
import repository from "./repository";
import IconMask from "../../IconMask";
import { LocalizationProvider, DesktopDatePicker, StaticDatePicker } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export default function DateTimeRange({ field, item = {}, path = '', ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState({
    [field?.startId]: item[field?.startId],
    [field?.endId]: item[field?.endId],
  });
  // console.log('field rangee', field);
  const theme = useTheme();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    [
      <InputLabel {...{
        sx: {
          // set the color of the label when not shrinked
          color: "gray",
          position: 'absolute',
          padding: '0px 10px',
          fontSize: 11,
          [`&.${inputLabelClasses.shrink}`]: {
            // set the color of the label when shrinked (usually when the TextField is focused)
            padding: '0px 14px',
          }
        }
      }} >
        {field?.label}
      </InputLabel>,
      <Box style={{ width: '100%', flexDirection: 'row', display: 'flex', marginTop: 16, borderRadius: 10, padding: 0, backgroundColor: 'rgba(200,200,200,0.1)', boxShadow: '0px 0px 2px gray' }}>
        <Button
          id="date"
          onClick={(e) => {
            setAnchorEl(null);
            setAnchorEl({
              anchorEl: e.currentTarget,
            });
          }}
          color="inherit"
          style={{
            textTransform: "none",
            ...(anchorEl?.id === "date"
              ? {
                boxShadow: "0px 0px 5px gray",
                borderBottom: `3px solid ${theme.palette.primary.main}`,
              }
              : {}),
          }}
        >
          {moment(value[field?.startId] || null).format("dddd, D MMM")}
        </Button>
        <Button
          id="start"
          onClick={(e) => {
            setAnchorEl({
              start: moment(value[field?.startId]).startOf('day'),
              anchorEl: e.currentTarget,
            });
          }}
          color="inherit"
          style={{
            textTransform: "none",
            ...(anchorEl?.id === "start"
              ? {
                boxShadow: "0px 0px 5px gray",
                borderBottom: `3px solid ${theme.palette.primary.main}`,
              }
              : {}),
          }}
        >
          {moment(value[field?.startId] || null).format("HH:mm")}
        </Button>
        <Box
          style={{
            padding: 8,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {<Typography>{"-"}</Typography>}
        </Box>
        <Button
          id="end"
          onClick={(e) => {
            setAnchorEl(null);
            setAnchorEl({
              start: moment(value[field?.startId]),
              anchorEl: e.currentTarget,
            });
          }}
          color="inherit"
          style={{
            textTransform: "none",
            ...(anchorEl?.id === "end"
              ? {
                boxShadow: "0px 0px 5px gray",
                borderBottom: `3px solid ${theme.palette.primary.main}`,
              }
              : {}),
          }}
        >
          {moment(value[field?.endId] || null).format("HH:mm")}
        </Button>
      </Box>,
      <input hidden id={path + (field?.startId || '')} value={value[field?.startId]?.format('YYYY-MM-DD HH:mm:ss') || null} readOnly />,
      <input hidden id={path + (field?.endId || '')} value={value[field?.endId]?.format('YYYY-MM-DD HH:mm:ss') || null} readOnly />,
      <Popper
        key={anchorEl?.id}
        open={!!anchorEl?.anchorEl}
        anchorEl={anchorEl?.anchorEl}
        style={{ maxHeight: 200, paddingTop: 4, zIndex: 9999 }}
        transition
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "center",
        // }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={500}>
            <Paper
              style={{
                maxHeight: anchorEl?.anchorEl?.id === "date" ? undefined : 200,
                overflow: "scroll",
                boxShadow: "0px 0px 20px gray",
              }}
            >

              {anchorEl?.anchorEl?.id === "date" && (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                >
                  <StaticDatePicker
                    showToolbar={false}
                    onClose={() => setAnchorEl(null)}
                    onChange={(d) => {
                      const e = moment(d)
                      const date = e.format('YYYY-MM-DD');
                      const start = moment(value[field?.startId]).format('HH:mm:ss');
                      const end = moment(value[field?.endId]).format('HH:mm:ss');
                      console.log('eeee', {
                        [field?.startId]: `${date} ${start}`,
                        [field?.endId]: `${date} ${end}`,
                      })
                      setValue({
                        ...value,
                        [field?.startId]: moment(`${date} ${start}`),
                        [field?.endId]: moment(`${date} ${end}`),
                      });
                    }}
                    onYearChange={() => setAnchorEl(null)}
                    views={["day", "month", "year"]}
                    value={value[field?.startId]}
                  />
                </LocalizationProvider>
              )}

              {(anchorEl?.anchorEl?.id === "start" || anchorEl?.anchorEl?.id === "end") && (
                <MenuList>
                  {repository
                    .generateTimeIntervals(5, anchorEl?.start || moment().startOf('day'), [])
                    .map((interval, index) => {
                      if (anchorEl?.anchorEl?.id === 'start' && value[field?.startId]?.format('YYYY-MM-DD HH:mm:ss') === interval.start) {
                        console.log('value[field?.startId]', value[field?.startId]?.format('YYYY-MM-DD HH:mm:ss'))
                        console.log('interval.start', interval.start)
                      } else if (value[field?.endId]?.format('YYYY-MM-DD HH:mm:ss') === interval.end) {
                        console.log('value[field?.endId]', value[field?.endId]?.format('YYYY-MM-DD HH:mm:ss'))
                        console.log('interval.end', interval.end)

                      }
                      const isSelected = anchorEl?.anchorEl?.id === 'start' ? value[field?.startId] * 1 === moment(interval.start) * 1 : value[field?.endId] * 1 === moment(interval.end) * 1;
                      const valueDisplay = anchorEl?.anchorEl?.id === 'start' ? interval.start : interval.end;

                      return (
                        <MenuItem
                          selected={isSelected}
                          autoFocus={isSelected}
                          onClick={() => {
                            setValue({
                              ...value,
                              [anchorEl?.anchorEl?.id === 'start' ? field?.startId : field?.endId]: moment(valueDisplay),
                            });
                            setAnchorEl(null);
                          }}
                        >
                          <Typography>
                            {moment(valueDisplay).format("HH:mm")}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </MenuList>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    ]
  );
}